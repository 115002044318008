import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private notification: NzNotificationService) {}

  createNotification(type: string, message: any): void {
    this.notification.create(type, '', message, {
      nzClass: 'notification-' + type,
      nzStyle: { width: '330px' },
    });
  }
}
