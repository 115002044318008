import { Injectable } from '@angular/core';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppPages, SIDEBAR_MENU_ITEMS, UserDetails } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userDetails = new BehaviorSubject<UserDetails>({});

  setUserDetails(userDetails: UserDetails): void {
    this.userDetails.next({ ...userDetails });
  }

  getUserDetails(): Observable<UserDetails> {
    return this.userDetails.asObservable();
  }

  hasAdminPermissions(): boolean {
    return this.userDetails?.value?.permissions?.includes('admin') || false;
  }

  getAvailableScreens(): RdSiderMenuItem[] {
    return this.hasAdminPermissions()
      ? [SIDEBAR_MENU_ITEMS[AppPages.HOME], SIDEBAR_MENU_ITEMS[AppPages.ADMIN]]
      : [SIDEBAR_MENU_ITEMS[AppPages.HOME]];
  }
}
