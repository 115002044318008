export interface HomeKpiResponse {
  status: string;
  data: any;
}
export interface HomeModalConfig {
  modalName: string;
  isVisible: boolean;
  modalTitle: string;
  modalWidth: string;
  footerButton: ButtonConfig[];
}

export interface ButtonConfig {
  btnText: string;
  btnType: any;
  btnStyle: any;
  isDisabled: () => boolean;
  handler: () => void;
  isLoading: () => boolean;
}
export interface ApproverList {
  status: string;
  users: Aprrovers[];
}
export interface Aprrovers {
  userId: string;
  username: string;
  isShared?: boolean;
  canEdit?: boolean;
  canClone?: boolean;
}

export const HOME_KPI = [
  {
    label: 'My Concepts',
    key: 'myConcepts',
    value: 0,
  },
  {
    label: 'Shared Concepts',
    key: 'privatelyShared',
    value: 0,
  },
  {
    label: 'Library',
    key: 'library',
    value: 0,
  },
  {
    label: 'All Concepts',
    key: 'allConcepts',
    value: 0,
  },
];

export const HOME_VIEW = [
  {
    label: '',
    value: 'list',
    icon: {
      type: 'custom:list-view',
      theme: 'fill',
    },
  },
  {
    label: '',
    value: 'tile',
    icon: {
      type: 'custom:tile-view',
      theme: 'fill',
    },
  },
];

export const BREADCRUMB_DATA = [
  {
    navigationPath: '/home',
    displayName: 'My Concepts',
  },
  {
    navigationPath: '',
    displayName: 'New Concept',
  },
];

export const SHARE_CONCEPT_RADIO_BUTTONS = [
  {
    label: 'Share Publicly',
    value: 'public',
    disabled: false,
  },
  {
    label: 'Share Privately',
    value: 'private',
    disabled: false,
  },
];

export const CONCEPT_TILE_DROPDOWN_ITEMS = () => [
  { label: 'Edit', value: 'edit', disabled: false },
  { label: 'Clone', value: 'clone', disabled: false },
  { label: 'Download Codes', value: 'downloadCodes', disabled: false },
];

export const CONCEPT_TABLE_COLUMNS = () => [
  {
    name: 'Concept Name',
    key: 'conceptName',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'clickable',
    setToLeft: true,
    width: '20%',
  },
  {
    name: 'Domain',
    key: 'domain',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    width: '20%',
  },
  {
    name: 'Publish Status',
    key: 'publishStatus',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Global Filter Status',
    key: 'globalFilterStatus',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Last Updated On',
    key: 'lastUpdatedOn',
    dataType: 'date',
    dateFormat: 'd MMM y',
    defaultSortOrder: 'desc',
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Actions',
    key: 'actions',
    dataType: 'string',
    align: 'left',
    type: 'actions',
    width: '15%',
    setToRight: true,
    actionIcons: [
      {
        type: 'custom:edit',
        key: 'edit',
        title: 'Edit',
        theme: 'fill',
      },
      {
        type: 'custom:editDisabled',
        key: 'editDisabled',
        title: 'Edit',
        theme: 'fill',
      },
      {
        type: 'custom:clone',
        key: 'clone',
        title: 'Clone',
        theme: 'fill',
      },
      {
        type: 'custom:cloneDisabled',
        key: 'cloneDisabled',
        title: 'Clone',
        theme: 'fill',
      },
      {
        type: 'custom:download',
        key: 'download',
        title: 'Export',
        theme: 'outline',
      },
      {
        type: 'custom:downloadDisabled',
        key: 'downloadDisabled',
        title: 'Export',
        theme: 'fill',
      },
      {
        type: 'custom:share',
        key: 'share',
        title: 'Share',
        theme: 'fill',
      },
      {
        type: 'custom:shareDisabled',
        key: 'shareDisabled',
        title: 'Share',
        theme: 'fill',
      },
      {
        type: 'custom:delete',
        key: 'delete',
        title: 'Delete',
        theme: 'fill',
      },
      {
        type: 'custom:deleteDisabled',
        key: 'deleteDisabled',
        title: 'Delete',
        theme: 'fill',
      },
      {
        type: 'cloud-download',
        key: 's3location',
        title: 'S3',
        theme: 'outline',
      },
    ],
  },
];

export const APPROVER_TABLE_COLUMNS = () => [
  {
    name: 'Name',
    key: 'approverName',
    dataType: 'string',
    align: 'left',
    width: '45%',
  },
  {
    name: 'Edit',
    key: 'editLabel',
    dataType: 'checkbox',
    align: 'left',
  },
  {
    name: 'Clone',
    key: 'cloneLabel',
    dataType: 'checkbox',
    align: 'left',
  },
  {
    name: 'Actions',
    key: 'actions',
    dataType: 'string',
    align: 'left',
    type: 'actions',
    width: '18%',
    setToRight: true,
    actionIcons: [
      {
        type: 'custom:delete',
        key: 'Remove',
        title: 'Delete',
        theme: 'fill',
      },
    ],
  },
];

export const VOCAB_TABLE_COLUMNS = () => [
  {
    name: 'Vocabulary',
    key: 'vocabularyName',
    dataType: 'string',
    align: 'left',
    type: 'text',
    width: '30%',
  },
  {
    name: 'Domain',
    key: 'domain',
    dataType: 'string',
    align: 'left',
    type: 'text',
    width: '70%',
  },
];

export const AUDIT_TABLE_COLUMNS = () => [
  {
    name: 'Last Modified On',
    key: 'lastModifiedOn',
    dataType: 'date',
    dateFormat: 'd MMM y',
    align: 'left',
    type: 'text',
    width: '20%',
  },
  {
    name: 'Last Modified By',
    key: 'lastModifiedBy',
    dataType: 'string',
    align: 'left',
    type: 'text',
    width: '20%',
  },
  {
    name: 'Comments',
    key: 'comments',
    dataType: 'string',
    align: 'left',
    type: 'text',
    ellipsis: 100,
    width: '60%',
  },
];

export const CODE_TABLE_COLUMNS = () => [
  {
    name: 'Code',
    key: 'code',
    dataType: 'string',
    align: 'left',
    type: 'text',
  },
  {
    name: 'Description',
    key: 'description',
    dataType: 'string',
    align: 'left',
    type: 'text',
    ellipsis: 50,
  },
  {
    name: 'Vocabulary',
    key: 'vocabulary',
    dataType: 'string',
    align: 'left',
    type: 'text',
  },
  {
    name: 'Domain',
    key: 'domain',
    dataType: 'string',
    align: 'left',
    type: 'text',
  },
];
