export const API_SUCCESS = 'SUCCESS';

export const SOMETHING_WENT_WRONG =
  'Something went wrong. Please try again later.';

export const DOWNLOAD_ERROR = 'Failed to download file';

export const DATA_SAVED_SUCCESS = 'Data saved successfully';

export const DOWNLOAD_SUCCESS = 'Concept Details downloaded successfully';

export const IMPORT_TEMPLATE_SUCCESS =
  'Import Template downloaded successfully';

export const IMPORT_TEMPLATE_FAILED = 'Failed to download import template';

export const DELETE_SUCCESS = 'Concept Set deleted Successfully!';

export const COPY_TO_CLIPBOARD =
  'S3Location successfully copied to Clipboard!!';

export const CONCEPT_NO_DATA = 'No concepts available.';

export const AUDIT_NO_DATA = 'No records available.';

export const CONCEPT_NAME_REQUIRED = 'Concept Name is Required field.';

export const CONCEPT_ALREADY_EXISTS = 'Concept Set Title already exists';

export const MIN_CONCEPT_NAME =
  'Enter concept name to start with minimum 3 characters';

export const SHARE_CONCEPT_SUCCESS = 'Concept shared successfully';

export const CONCEPT_NOT_DELETABLE =
  'Concept cannot be cloned as the original concept was deleted.';

export const CONCEPT_SPECIAL_CHAR =
  'Only alphanumeric characters, spaces, hyphens and underscores allowed for the Concept Set Name';

export const MAX_LENGTH_CONCEPT_NAME =
  'Concept Set name exceeds maximum characters allowed';

export const DESCRIPTION_LENGTH_EXCEED =
  'Concept Set description exceeds maximum characters allowed';

export const CONCEPT_REJECTED_SUCCESS = 'Concept has been rejected';

export const CONCEPT_APPROVED_SUCCESS = 'Concept successfully approved';

export const ONBOARDING_FAILED = 'Failed to onboard datasets';

export const ONBOARDING_SUCCESS =
  'Concepts successfully added for concept set onboarding queue';

export const APPROVER_LIST_FAILED = 'Failed to fetch approver list';

export const FAILED = 'Failed to fetch data';

export const NO_DATA = 'No results found.';

export const NO_DATA_AVAILABLE = 'No data available';

export const FILE_TYPE_ERROR = 'Please upload .xlsx file only';

export const EMPTY_FILE_ERROR = 'Empty file cannot be uploaded';

export const NO_CODES_PRESENT = 'No codes present to import';

export const FILE_INVALID_COLUMNS = 'Invalid columns in file';

export const MAX_LENGTH_COMMENT = 'Comments exceed maximum characters allowed';

export const SAVE_CODES_ERROR = 'Save code error';
