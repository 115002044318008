import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  API_SUCCESS,
  ApiEndpoints,
  CONCEPT_NO_DATA,
  SOMETHING_WENT_WRONG,
} from '@core/constants';
import {
  AdminModalConfig,
  CODES_TABLE_COLUMNS,
  CONCEPT_LOG_COLUMNS,
  ModalButtonConfig,
} from '@core/models';
import { TransactionManagerService } from '@core/services';
import { Utils } from '@shared/Utils';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-concept-onboarding',
  templateUrl: './concept-onboarding.component.html',
  styleUrls: ['./concept-onboarding.component.scss'],
})
export class ConceptOnboardingComponent implements OnInit, OnDestroy {
  public pageParams = {
    legendData: [
      { id: 'step1', value: 'Datacut Generated' },
      { id: 'step2', value: 'Metrics Calculated' },
      { id: 'step3', value: 'Global Filter Created' },
    ],
    legendIconStyle: { fontSize: '18px', color: '#fff', marginRight: '5px' },
    lineStyle: { fontSize: '16px' },
    statusData: [
      { id: 1, value: 'Success', icon: 'custom:success' },
      { id: 2, value: 'Failed', icon: 'custom:failed' },
      { id: 3, value: 'Waiting', icon: 'custom:waiting' },
    ],
    showTaggingPopup: false,
  };

  conceptLogGrid = {
    tableData: [],
    tableColumns: CONCEPT_LOG_COLUMNS(),
    tableScrollDetails: { y: '350px' },
    pageIndex: 1,
    totalConceptsCount: 0,
    sortKey: 'submittedOn',
    sortType: 'desc',
    errorMessage: '',
    status: 'loading',
    limit: 10,
    offset: 0,
  };

  modalConfig: AdminModalConfig = {
    modalName: '',
    isVisible: false,
    modalTitle: '',
    modalWidth: '',
  };

  isConceptLoaded: boolean = false;

  public codesAccordion: any[] = [];

  conceptLoadErrorMessage: string = '';

  public codeDetails = {
    conceptSetName: '',
    conceptSetDesc: '',
    totalNumOfCodes: '',
    codes: {
      drug: {
        noOfCodes: null,
        data: [],
      },
      condition: {
        noOfCodes: null,
        data: [],
      },
      procedure: {
        noOfCodes: null,
        data: [],
      },
      measurement: {
        noOfCodes: null,
        data: [],
      },
      provider: {
        noOfCodes: null,
        data: [],
      },
    },
  };

  errorLogDrawerParams = {
    show: false,
    data: null,
  };

  public listSubscription: Subscription = new Subscription();

  constructor(private dataService: TransactionManagerService) {}

  ngOnInit() {
    this.loadLogData();
  }

  loadLogData() {
    this.conceptLogGrid.status = 'loading';
    const payload = {
      sortKey: this.conceptLogGrid.sortKey,
      sortType: this.conceptLogGrid.sortType,
      limit: this.conceptLogGrid.limit,
      offset: this.conceptLogGrid.offset,
    };
    this.listSubscription?.unsubscribe();
    this.listSubscription = this.dataService
      .post(ApiEndpoints.CONCEPT_LOG, payload)
      .subscribe({
        next: (response: any) => {
          if (response?.status === API_SUCCESS) {
            this.conceptLogGrid.tableData = response?.data;
            this.conceptLogGrid.totalConceptsCount = response.conceptsCount;
            if (this.conceptLogGrid.tableData.length === 0) {
              this.conceptLogGrid.errorMessage = CONCEPT_NO_DATA;
              this.conceptLogGrid.status = 'unavailable';
            } else {
              this.transformGridData();
              this.conceptLogGrid.status = 'available';
            }
          } else {
            this.conceptLogGrid.status = 'unavailable';
            this.conceptLogGrid.errorMessage = SOMETHING_WENT_WRONG;
          }
        },
        error: () => {
          this.conceptLogGrid.status = 'unavailable';
          this.conceptLogGrid.errorMessage = SOMETHING_WENT_WRONG;
        },
      });
  }

  handleIconClick($event: any): void {
    // TODO: Add type.
    if (
      $event?.column?.key === 'status' &&
      $event?.icon?.type === 'custom:failed'
    ) {
      this.errorLogDrawerParams.data = $event.data;
      this.openErrorLogDrawer();
    }
  }

  openErrorLogDrawer(): void {
    this.errorLogDrawerParams.show = true;
  }

  handleErrorLogDrawerClose(): void {
    this.errorLogDrawerParams.show = false;
  }

  loadCodeDetails(conceptId: number) {
    const payload = {
      searchText: '',
      conceptSetId: conceptId,
    };
    this.isConceptLoaded = false;
    this.conceptLoadErrorMessage = '';
    this.dataService.post(ApiEndpoints.CODE_DETAILS, payload).subscribe({
      next: (response: any) => {
        if (response?.status === API_SUCCESS) {
          this.codeDetails = response;
          this.codesAccordion = [];
          Object.entries(this.codeDetails.codes).map((category: any) => {
            const panelHeader =
              '<div class="d-flex justify-content-between w-100"><div class="d-flex justify-content-between fw-bold fs-16">' +
              `${Utils.titleCase(category[0])}` +
              '</div><div class="fw-bold fs-16">' +
              category[1].noOfCodes +
              ' Codes</div></div>';
            this.codesAccordion.push({
              key: category[0],
              codeCount: category[1].noOfCodes,
              tableData: category[1].data.sort((a: any, b: any) =>
                a.vocabulary.localeCompare(b.vocabulary)
              ),
              tableColumn: CODES_TABLE_COLUMNS(),
              panelHeader: panelHeader,
              showArrowIcon: true,
            });
          });
          // re-order codes data
          this.codesAccordion.sort((a, b) => a.key.localeCompare(b.key));
          this.isConceptLoaded = true;
        } else {
          this.isConceptLoaded = false;
          this.conceptLoadErrorMessage = SOMETHING_WENT_WRONG;
        }
      },
      error: () => {
        this.isConceptLoaded = false;
        this.conceptLoadErrorMessage = SOMETHING_WENT_WRONG;
      },
    });
  }

  transformGridData() {
    this.conceptLogGrid.tableData.map((data: any) => {
      this.formatRowIcons(data);
      Utils.formatGridData(data);
      if (data.onboardedOn) {
        data.onboardedOn_iconAfter = [
          {
            type: 'info-circle',
            style: { fontSize: '15px' },
            title: '',
            tooltip: `${Utils.getTime(data.onboardedOn)} GMT`,
          },
        ];
      }
      if (data.lastUpdatedDate) {
        data.lastUpdatedDate_iconAfter = [
          {
            type: 'info-circle',
            style: { fontSize: '15px' },
            title: '',
            tooltip: `${Utils.getTime(data.lastUpdatedDate)} GMT`,
          },
        ];
      }
      if (data.status === undefined) {
        data.status = '';
      }
    });
  }

  formatRowIcons(data: any) {
    if (
      data.datacutStatus === 'Waiting' &&
      data.metricsStatus === 'Waiting' &&
      data.filterStatus === 'Waiting'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Datacut Generation',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Metrics Calculation',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Global Filter Creation',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Success' &&
      data.metricsStatus === 'Waiting' &&
      data.filterStatus === 'Waiting'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Datacut Generated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Metrics Calculation',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Global Filter Creation',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Success' &&
      data.metricsStatus === 'Failed' &&
      data.filterStatus === 'Waiting'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Datacut Generated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
            title: '',
          },
        },
        {
          type: 'custom:failed',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Click icon to view error log',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Global Filter Creation',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Success' &&
      data.metricsStatus === 'Success' &&
      data.filterStatus === 'Waiting'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Datacut Generated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Metrics Calculated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Global Filter Creation',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Success' &&
      data.metricsStatus === 'Success' &&
      data.filterStatus === 'Failed'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Datacut Generated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Metrics Calculated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:failed',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Click icon to view error log',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Success' &&
      data.metricsStatus === 'Success' &&
      data.filterStatus === 'Success'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Datacut Generated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Metrics Calculated',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:success',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Global Filter Created',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Failed' &&
      data.metricsStatus === 'Waiting' &&
      data.filterStatus === 'Waiting'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:failed',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Click icon to view error log',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Metrics Calculation',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:waiting',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Global Filter Creation',
          title: '',
        },
      ];
    } else if (
      data.datacutStatus === 'Failed' &&
      data.metricsStatus === 'Failed' &&
      data.filterStatus === 'Failed'
    ) {
      data.status_iconAfter = [
        {
          type: 'custom:failed',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Click icon to view error log',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:failed',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Click icon to view error log',
          title: '',
        },
        {
          type: 'custom:dash',
          style: {
            'font-size': '10px',
            'vertical-align': '0.2em',
          },
          title: '',
        },
        {
          type: 'custom:failed',
          style: {
            'font-size': '18px',
          },
          tooltip: 'Click icon to view error log',
          title: '',
        },
      ];
    }
  }

  onTableSort(event: any) {
    this.conceptLogGrid.sortType = event.defaultSortOrder;
    this.conceptLogGrid.sortKey = event.key;
    this.loadLogData();
  }

  onPaginationChange(event: any) {
    this.conceptLogGrid.pageIndex = event;
    this.conceptLogGrid.offset = (event - 1) * 10;
    this.loadLogData();
  }

  onTableRowClick(event: any) {
    if (event.column.key === 'conceptName') {
      this.loadCodeDetails(event.data.conceptId);
      this.openModal('conceptName', `${event.data.conceptName}`, '850px');
      this.modalConfig.isVisible = true;
    } else if (event.column.key === 'datasetName') {
      window.open(
        environment.config.dataExplorerRedirectUrl +
          '/datasets/dataset-details/' +
          event.data.datasetId
      );
    }
  }

  private openModal(
    modalName: string,
    modalTitle: string,
    modalWidth: string,
    footerButton?: ModalButtonConfig[]
  ): void {
    this.modalConfig = {
      modalName,
      isVisible: true,
      modalTitle,
      modalWidth,
      footerButton,
    };
  }

  onClose(event: any) {
    this.pageParams.showTaggingPopup = false;
    if (event.refreshGrid) this.loadLogData();
  }

  ngOnDestroy() {
    this.listSubscription?.unsubscribe();
  }
}
