import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  API_SUCCESS,
  ApiEndpoints,
  FAILED,
  ONBOARDING_FAILED,
  ONBOARDING_SUCCESS,
} from '@core/constants';
import { DATASET_COLUMNS } from '@core/models';
import { TransactionManagerService } from '@core/services';
import { CommonService } from '@core/services/common.service';

@Component({
  selector: 'app-onboard-dataset',
  templateUrl: './onboard-dataset.component.html',
  styleUrls: ['./onboard-dataset.component.scss'],
})
export class OnboardDatasetComponent implements OnInit {
  @Input() isVisibleMiddle: boolean = false;

  @Output() closed = new EventEmitter<any>();

  public conceptDropdown = {
    items: [],
    selectedValue: '',
  };

  public datasetDropdown = {
    items: [],
    selectedValue: '',
  };

  public pageParams = {
    customPath: '',
    isCustomPathInvalid: false,
    isDatasetOnboarded: false,
    isAddDisabled: false,
    isAddedToGrid: false,
  };

  public onboardingGrid = {
    tableData: [] as any,
    tableColumns: DATASET_COLUMNS(),
  };

  constructor(
    private dataService: TransactionManagerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.loadDropdowns();
    // this.onboardingGrid.tableData = [
    //   { conceptName: '1', dataSourceName: '2', visibleActionIcons: ['delete'] },
    // ];
  }

  onDropdownChange(name: any, event: any) {
    this.pageParams.isDatasetOnboarded = false;
    this.pageParams.isCustomPathInvalid = false;
    name.selectedValue = event;
  }

  loadDropdowns() {
    this.dataService.get(ApiEndpoints.ONBOARDING_DETAILS).subscribe({
      next: (response) => {
        if (response.status === API_SUCCESS) {
          this.conceptDropdown.items = this.formatData(
            response.conceptSetDetails,
            'conceptSetId',
            'conceptSetName'
          );
          this.datasetDropdown.items = this.formatData(
            response.datasetDetails,
            'dataSourceId',
            'dataSourceName'
          );
        }
      },
      error: () => {},
    });
  }

  formatData(data: any, value: string, key: string) {
    data.map((item: any) => {
      item.label = item[key];
      item.value = item[value];
    });
    return data;
  }

  validateField() {
    if (this.pageParams.customPath.trim()) {
      this.pageParams.isCustomPathInvalid =
        !this.pageParams.customPath.startsWith('s3://');
    }
  }

  isAddDisabled() {
    // return false;
    // check for custom path
    if (
      this.pageParams.isDatasetOnboarded &&
      this.pageParams.customPath.trim() &&
      !this.pageParams.isCustomPathInvalid
    ) {
      return false;
    }
    if (
      this.conceptDropdown.selectedValue &&
      this.datasetDropdown.selectedValue &&
      !this.pageParams.isDatasetOnboarded
    ) {
      // add code to check if set is already added to grid then add dataset will be disabled
      const conIndex = this.onboardingGrid.tableData.findIndex(
        (data: any) =>
          data.conceptSetId === this.conceptDropdown.selectedValue &&
          data.dataSourceId === this.datasetDropdown.selectedValue
      );
      if (conIndex === -1) {
        return false;
      }
      return true;
    } else return true;
  }
  addDataset() {
    let trimPath = this.pageParams.customPath.trim();
    if (
      this.pageParams.isDatasetOnboarded &&
      trimPath &&
      !this.pageParams.isCustomPathInvalid
    ) {
      this.addRecordToTable();
      this.pageParams.isDatasetOnboarded = false;
    } else {
      const payload = {
        conceptId: this.conceptDropdown.selectedValue,
        datasourceId: this.datasetDropdown.selectedValue,
      };
      this.dataService
        .post(ApiEndpoints.ONBOARDING_DETAILS, payload)
        .subscribe({
          next: (response) => {
            if (response?.status === API_SUCCESS) {
              if (response.isOnboarded) {
                // show alert message
                this.pageParams.isDatasetOnboarded = true;
                this.addRecordToTable();
                this.pageParams.isDatasetOnboarded = false;
              } else {
                this.checkRecordState();
                this.pageParams.isAddedToGrid = true;
              }
            } else {
              // show error message
              this.commonService.createNotification('error', FAILED);
            }
          },
          error: () => {
            this.commonService.createNotification('error', FAILED);
          },
        });
    }
  }

  addRecordToTable() {
    let conName: any = this.conceptDropdown.items.find(
      (data: any) => data.value === this.conceptDropdown.selectedValue
    );
    let setName: any = this.datasetDropdown.items.find(
      (data: any) => data.value === this.datasetDropdown.selectedValue
    );
    const data = {
      conceptName: conName.label,
      conceptSetId: conName.value,
      dataSourceName: setName.label,
      dataSourceId: setName.value,
      path: this.pageParams.customPath,
      visibleActionIcons: ['delete'],
      disabledActionIcons: [],
    };
    this.onboardingGrid.tableData = [data, ...this.onboardingGrid.tableData];
    this.onboardingGrid.tableData.sort((a: any, b: any) =>
      a.conceptName.localeCompare(b.conceptName)
    );
  }

  checkRecordState() {
    const conIndex = this.onboardingGrid.tableData.findIndex(
      (data: any) =>
        data.conceptSetId === this.conceptDropdown.selectedValue &&
        data.dataSourceId === this.datasetDropdown.selectedValue
    );
    if (conIndex === -1) {
      this.addRecordToTable();
    } else return;
  }

  deleteDataset(event: any) {
    if (event.icon.key === 'delete') {
      this.onboardingGrid.tableData.splice(event.index, 1);
      this.onboardingGrid.tableData = [...this.onboardingGrid.tableData];
    }
  }

  closeModal() {
    this.isVisibleMiddle = false;
    this.closed.emit({ refreshGrid: false });
  }

  onboardConcepts() {
    if (this.onboardingGrid.tableData.length > 0) {
      const payload = {
        selected: this.formPayload(),
      };
      this.dataService
        .post(ApiEndpoints.TRIGGER_ONBOARDING, payload)
        .subscribe({
          next: (response) => {
            if (response.status === API_SUCCESS) {
              if (response.onboardingSuccess) {
                this.commonService.createNotification(
                  'success',
                  ONBOARDING_SUCCESS
                );
                this.isVisibleMiddle = false;
                this.closed.emit({ refreshGrid: true });
              }
            } else {
              this.commonService.createNotification('error', ONBOARDING_FAILED);
            }
          },
          error: () => {
            this.commonService.createNotification('error', ONBOARDING_FAILED);
          },
        });
    }
  }

  formPayload() {
    const tempObj: any = [];
    this.onboardingGrid.tableData.map((data: any) => {
      const index = tempObj.findIndex(
        (row: any) => row.conceptSetId === data.conceptSetId
      );
      if (index === -1) {
        tempObj.push({
          conceptSetId: data.conceptSetId,
          dataSources: [
            {
              datasourceId: data.dataSourceId,
              datasourceName: data.dataSourceName,
              path: data.path,
              onboarding: false,
            },
          ],
        });
      } else {
        tempObj[index].dataSources.push({
          datasourceId: data.dataSourceId,
          datasourceName: data.dataSourceName,
          path: data.path,
          onboarding: false,
        });
      }
    });
    return tempObj;
  }
}
