import { Component, OnInit } from '@angular/core';
import { ApiEndpoints, SOMETHING_WENT_WRONG } from '@core/constants';
import { LaunchBarData } from '@core/models';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/shared/services/app-state';

import {
  AuthService,
  CustomIconService,
  TransactionManagerService,
} from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading = false;

  errorMessage = '';

  launchBarData?: LaunchBarData;

  sideBarMenuItemsList: RdSiderMenuItem[] = [];

  appStateValues: any = {};

  showErrorMessage = true;
  authErrorMessage: any = 'Authenticating, Please wait...';
  appStateSubs: any;
  authServiceSubs: any;
  pageInitialized = false;

  constructor(
    private customIconService: CustomIconService,
    private dataFetchService: TransactionManagerService,
    private auth: AuthService,
    private authService: AuthenticationService,
    private appState: AppState,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.customIconService.addCustomIconsToNzLibrary();
  }

  ngOnInit(): void {
    if (environment.authEnabled) {
      this.authenticateUser();
      this.appStateSubs = AppState.appState.subscribe((val: any) => {
        if (val && Object.keys(val).length > 0) {
          this.appStateValues = val;
        } else {
          this.appStateValues = {};
        }
        if (!this.pageInitialized) {
          if (this.cookieService.get(this.authService.accessTokenKey)) {
            this.showErrorMessage = false;
            this.fetchLaunchBar();
          } else {
            const code = this.getUrlParam('code');
            if (code === 'undefined' || !code) {
              this.authService.login();
            } else if (code) {
              this.router.navigate([], {
                queryParams: {
                  yourParamName: null,
                  youCanRemoveMultiple: null,
                },
                queryParamsHandling: 'merge',
              });
              this.authService.getAccessToken(code);
            }
          }
        }
        this.pageInitialized = true;
      });
    } else {
      this.showErrorMessage = false;
      this.fetchLaunchBar();
    }
  }

  authenticateUser() {
    this.authServiceSubs = this.authService.authResponse.subscribe((res) => {
      if (res['valid']) {
        this.showErrorMessage = false;
        this.fetchLaunchBar();
      } else {
        this.showErrorMessage = true;
        this.authErrorMessage = res['message'] || 'Something went wrong.';
      }
    });
  }

  fetchLaunchBar() {
    this.isLoading = true;
    const launchBar$ = this.dataFetchService.get(ApiEndpoints.LAUNCH_BAR);
    const user$ = this.dataFetchService.get(ApiEndpoints.USER_LOGIN);
    forkJoin([launchBar$, user$]).subscribe({
      next: ([launchBarResponse, userDetailsResponse]) => {
        if (launchBarResponse?.applications?.applications?.length) {
          this.launchBarData = { ...launchBarResponse.applications };
          if (userDetailsResponse) {
            this.auth.setUserDetails(userDetailsResponse);
            this.sideBarMenuItemsList = this.auth.getAvailableScreens();
            localStorage.setItem('region', userDetailsResponse.region);
          }
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
        this.isLoading = false;
      },
      error: (error) => {
        if (error?.status === 401) {
          this.errorMessage = '';
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
          this.isLoading = false;
        }
      },
    });
  }

  getUrlParam(key: string) {
    const results = new RegExp('[?&]' + key + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }
}
