<div class="approve__container">
  <div class="search-container d-flex justify-content-between">
    <div class="d-flex align-center">
      <label>Concept Requests</label>
      <div>
        <rd-input
          [isInputGroup]="true"
          [groupAddOnBefore]="prefixIconButton"
          [groupAddOnAfter]="suffixIconButton"
        >
          <rd-input
            [currentValue]="pageParams.searchText.trim()"
            (modelChangeEvent)="
              pageParams.searchText = $event.trim();
              pageParams.searchText.length < 1 &&
                pageParams.isSearchApplied &&
                resetSearch(pageParams.searchText, 'code')
            "
            placeholder="Search"
          ></rd-input>
        </rd-input>
        <ng-template #suffixIconButton>
          <rd-button
            btnType="default"
            [isIconNeeded]="true"
            iconType="search"
            [btnStyle]="searchButtonStyle"
            [isDisabled]="pageParams.searchText!.length < 3"
            (btnClick)="pageParams.isSearchApplied = true; onSearch()"
          ></rd-button>
        </ng-template>
        <ng-template #prefixIconButton>
          <rd-select
            mode="default"
            [isKeyLabelOptions]="true"
            [selectedItems]="pageParams.searchColumn"
            (selectedItemsEvent)="pageParams.searchColumn = $event"
            [options]="searchSelectOptions"
            placeholder="Select"
            [selectStyle]="{ width: '150px', textAlign: 'left' }"
          ></rd-select>
        </ng-template>
      </div>
    </div>
    <div class="error-section" *ngIf="conceptStatus.errorMessage">
      <rd-alert
        [type]="conceptStatus.errorType === 'success' ? 'success' : 'error'"
        [closable]="true"
        [showIcon]="true"
        [message]="conceptStatus.errorMessage"
      ></rd-alert>
    </div>
  </div>
  <div class="grid-container mt-20">
    <rd-table
      [tableData]="conceptRequestGrid.tableData"
      [tableColumns]="conceptRequestGrid.tableColumns"
      [isLoading]="conceptRequestGrid.status === 'loading'"
      [errorMessage]="conceptRequestGrid.errorMessage"
      [pageIndex]="conceptRequestGrid.pageIndex"
      [pageSize]="10"
      [loadingRows]="8"
      [tableScrollDetails]="conceptRequestGrid.tableScrollDetails"
      [totalCount]="conceptRequestGrid.totalRequestsCount"
      [frontEndPagination]="false"
      [showPagination]="true"
      [showTotal]="tableTotal"
      (paginationChange)="onPaginationChange($event)"
      (tableRowClick)="onTableRowClick($event)"
      (linkColumnCellClicked)="onLinkedColumnClick($event)"
      (sortApply)="onTableSort($event)"
    >
      <ng-template #tableTotal let-range="range" let-total>
        {{ range[0] }} - {{ range[1] }} of {{ total }} Concepts
      </ng-template></rd-table
    >
  </div>
</div>

<div class="modal__container">
  <rd-modal
    *ngIf="modalConfig.isVisible"
    [isVisible]="modalConfig.isVisible"
    [isCloseable]="true"
    [modalWidth]="modalConfig.modalWidth"
    [isMaskClosable]="false"
    [isCentered]="true"
    modalDialogClassName="modal-box"
    (modalCloseEvent)="modalConfig.isVisible = false"
  >
    <ng-container rdModalTitle>
      <div class="modal-title">
        <b>{{ modalConfig.modalTitle }}</b>
      </div>
    </ng-container>
    <ng-container rdModalContent>
      <ng-container *ngIf="modalConfig.modalName === 'reviewConcept'">
        <div class="review__container">
          <ng-container *ngIf="isConceptLoaded">
            <div class="mb-10">
              <label class="fw-bold fs-16">Description: </label>
              <span class="fw-normal fs-16">{{
                codeDetails.conceptSetDesc || '-'
              }}</span>
            </div>
            <div class="d-flex justify-content-between align-center mb-15">
              <div class="search-bar">
                <rd-input
                  [isInputGroup]="true"
                  [groupAddOnAfter]="suffixIconButton"
                >
                  <rd-input
                    [currentValue]="pageParams.searchText"
                    (modelChangeEvent)="
                      pageParams.searchText = $event;
                      pageParams.searchText.length < 1 &&
                        pageParams.isSearchApplied &&
                        resetSearch(pageParams.searchText, 'dataset')
                    "
                    placeholder="Search Concept Code or Description"
                  ></rd-input>
                </rd-input>
                <ng-template #suffixIconButton>
                  <rd-button
                    btnType="default"
                    [isIconNeeded]="true"
                    iconType="search"
                    [btnStyle]="searchButtonStyle"
                    [isDisabled]="pageParams.searchText!.length < 3"
                    (btnClick)="
                      pageParams.isSearchApplied = true;
                      loadCodeDetails(clickedConceptData.data.conceptSetId)
                    "
                  ></rd-button>
                </ng-template>
              </div>
              <div>
                <div class="mb-15">
                  <label class="fw-bold fs-16">Total # of Codes: </label>
                  <span class="fw-bold fs-16">{{
                    codeDetails.totalNumOfCodes
                  }}</span>
                </div>
              </div>
            </div>
            <!-- <div
              class="d-flex align-center mb-15 mt-5"
              style="column-gap: 10px"
            >
              <label class="fw-bold">Description:</label>
              <span class="fs-16 fw-normal">
                {{ codeDetails.conceptSetDesc || '-' }}
              </span>
            </div> -->
            <div class="collapse-section">
              <rd-collapse
                [showArrowIcon]="true"
                [isAccordion]="true"
                [panels]="codesAccordion"
              >
                <ng-template let-header>
                  <rd-table
                    [tableData]="header.tableData"
                    [tableColumns]="header.tableColumn"
                    [tableScrollDetails]="{ y: '167px' }"
                    [bordered]="true"
                    [frontEndPagination]="false"
                    [showPagination]="false"
                  ></rd-table>
                </ng-template>
              </rd-collapse>
            </div>
          </ng-container>
          <ng-container *ngIf="!isConceptLoaded && conceptLoadErrorMessage">
            <div class="error-box">
              <rd-alert
                type="error"
                [closable]="false"
                [showIcon]="true"
                [message]="conceptLoadErrorMessage"
              ></rd-alert>
            </div>
          </ng-container>
          <ng-container *ngIf="!isConceptLoaded && !conceptLoadErrorMessage">
            <div class="spinner"><rd-spinner></rd-spinner></div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="modalConfig.modalName === 'rejectConcept'">
        <div class="reject__container">
          <div class="fs-16">Would you like to add a reason for rejection?</div>
          <div class="reject__content">
            <div class="fs-14 fw-normal mb-15">Reason(Optional)</div>
            <rd-input
              [isTextArea]="true"
              [customStyle]="{ 'min-height': '81px', resize: 'none' }"
              [showMaxCharacterCount]="false"
              placeholder="Add Comments for the concept"
              (modelChangeEvent)="conceptStatus.rejectComments = $event"
            ></rd-input>
            <div class="info">
              <i class="info__icon" nz-icon nzType="custom:info"></i>
              The Global Filter for concepts that are rejected is automatically
              set to 'No'
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="modalConfig.modalName === 'approveConcept'">
        <div class="approve_concept">
          <div class="fs-16 fw-normal">
            Approving this concept will publish it to the Concept Library. Would
            you like to proceed?
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container rdModalFooter *ngFor="let btn of modalConfig.footerButton">
      <rd-button
        [btnText]="btn.btnText"
        [btnType]="btn.btnType"
        [btnStyle]="btn.btnStyle"
        [isDisabled]="btn.isDisabled()"
        (btnClick)="btn.handler()"
      ></rd-button>
    </ng-container>
  </rd-modal>
</div>
