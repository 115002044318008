import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { localEndpointList } from '../constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class TransactionManagerService {
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
  }

  /**
   * Function to call an API with DELETE method.
   *
   * @param url
   * @param payload
   */
  public delete(url: string, payload: any = {}): Observable<any> {
    let queryParams = '';
    Object.entries(payload).map((item) => {
      if (queryParams === '') {
        queryParams = queryParams.concat('?' + item[0] + '=' + item[1]);
      } else {
        queryParams = queryParams.concat('&' + item[0] + '=' + item[1]);
      }
    });
    let apiEndPoint = this.createEndPoint(url);
    if (environment.env !== 'local' && Object.keys(payload).length !== 0) {
      apiEndPoint = apiEndPoint.concat(queryParams);
    }
    return this.http.delete(apiEndPoint, this.httpOptions);
  }

  /**
   * Function to call an API with GET method.
   *
   * @param url
   * @param payload
   * @param isFileDownload
   */
  public get(
    url: string,
    payload: any = {},
    isFileDownload: boolean = false
  ): Observable<any> {
    let queryParams = '';
    Object.entries(payload).map((item) => {
      if (queryParams === '') {
        queryParams = queryParams.concat('?' + item[0] + '=' + item[1]);
      } else {
        queryParams = queryParams.concat('&' + item[0] + '=' + item[1]);
      }
    });
    if (isFileDownload) {
      this.httpOptions['responseType'] = 'blob';
    } else {
      delete this.httpOptions?.responseType;
    }
    let apiEndPoint = this.createEndPoint(url);
    if (environment.env !== 'local' && Object.keys(payload).length !== 0) {
      apiEndPoint = apiEndPoint.concat(queryParams);
    }
    return this.http.get(apiEndPoint, this.httpOptions);
  }

  /**
   * Function to call an API with POST method.
   *
   * @param url
   * @param payload
   * @param setHeader
   * @param isFileDownload
   */
  public post(
    url: string,
    payload: any = {},
    isFileDownload: boolean = false,
    setHeader: boolean = true
  ): Observable<any> {
    let apiEndPoint = this.createEndPoint(url);
    if (isFileDownload) {
      this.httpOptions['responseType'] = 'blob';
    } else {
      delete this.httpOptions?.responseType;
    }
    if (setHeader)
      return this.http.post(apiEndPoint, payload, this.httpOptions);
    else return this.http.post(apiEndPoint, payload, { withCredentials: true });
    // return this.http.post(apiEndPoint, payload, this.httpOptions);
  }

  /**
   * Function to call an API with PUT method.
   *
   * @param url
   * @param body
   */
  public put(url: string, body: any): Observable<any> {
    let apiEndPoint = this.createEndPoint(url);
    return this.http.put(apiEndPoint, body, this.httpOptions);
  }

  /**
   * Function to call an API with PATCH method.
   *
   * @param url
   * @param payload
   */
  public patch(url: string, payload: any = {}): Observable<any> {
    let apiEndPoint = this.createEndPoint(url);
    return this.http.patch(apiEndPoint, payload, this.httpOptions);
  }

  private createEndPoint(url: string): string {
    if (localEndpointList.includes(url)) {
      return `https://localhost:4200/api/${url}`;
    } else {
      return `${environment.config.serverUrl}/${url}`;
    }
  }
}
