<div class="audit-container">
  <div class="filter-row">
    <div class="text heading">Concept</div>
    <rd-select
      [mode]="'default'"
      [options]="dropDownData.concept_type"
      [placeholder]="'Please select a Concept Type'"
      [isLoading]="isDropDownLoading"
      [selectStyle]="{ width: '150px' }"
      [selectedItems]="dropDownData.selectedConceptType"
      (selectedItemsEvent)="onDropdownChange($event, 'conceptType')"
    ></rd-select>
    <rd-select
      [mode]="'default'"
      [isKeyLabelOptions]="true"
      [options]="
        dropDownData.selectedConceptType === 'Active'
          ? dropDownData.activeConcept
          : dropDownData.deletedConcept
      "
      [placeholder]="'Please select a Concept'"
      [isLoading]="isDropDownLoading"
      [selectStyle]="{ width: '250px' }"
      [showSearchBox]="true"
      [selectedItems]="dropDownData.selectedConcept"
      (selectedItemsEvent)="onDropdownChange($event, 'concept')"
    ></rd-select>
    <div class="text">
      <span style="font-weight: bold">Created On:</span>
      {{ auditGrid.createdOn | date : 'd MMM y' }}
    </div>
    <div class="text">
      <span style="font-weight: bold">Created By:</span>
      {{ auditGrid.createdBy }}
    </div>
  </div>
  <div class="table-row">
    <rd-table
      [tableData]="auditGrid.tableData"
      [tableColumns]="auditGrid.tableColumns"
      [isLoading]="auditGrid.isLoading"
      [errorMessage]="auditGrid.errorMessage"
      [pageIndex]="auditGrid.pageIndex"
      [pageSize]="10"
      [loadingRows]="8"
      [totalCount]="auditGrid.totalRequestsCount"
      [frontEndPagination]="true"
      [showPagination]="true"
      [showTotal]="tableTotal"
      [tableScrollDetails]="{ y: '390px' }"
    >
      <ng-template #tableTotal let-range="range" let-total>
        {{ range[0] }} - {{ range[1] }} of {{ total }} Records
      </ng-template></rd-table
    >
  </div>
</div>
