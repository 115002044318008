<div class="onboarding-container">
  <div class="d-flex justify-content-between align-center">
    <div class="admin-services__header-left">
      <label>Concept Onboarding Log</label>
      <rd-button
        btnText="Data Refresh"
        btnType="default"
        iconType="sync"
        [isIconNeeded]="true"
        (btnClick)="loadLogData()"
      ></rd-button>
    </div>
    <div class="admin-services__header-right">
      <rd-button
        btnText="Concept Tagging"
        iconType="plus"
        [isIconNeeded]="true"
        (click)="pageParams.showTaggingPopup = true"
      ></rd-button>
    </div>
  </div>
  <div class="d-flex justify-content-end align-center mt-20">
    <div class="d-flex align-center">
      <span class="mr-10 font-16"><b>Legend: </b></span>
      <span class="mr-5"> <b>Progress Indicator:</b></span>
    </div>
    <div
      class="d-flex align-center mr-5"
      *ngFor="let data of pageParams.legendData"
    >
      <i
        nz-icon
        nzType="custom:{{ data.id }}"
        [style]="pageParams.legendIconStyle"
      ></i>
      <span class="mr-5">{{ data.value }}</span>
      <span class="mr-5" *ngIf="data.id !== 'step3'"> &#8212; </span>
    </div>
    <div>
      <i nz-icon nzType="custom:line" [style]="pageParams.lineStyle"></i>
    </div>
    <span class="mr-10"><b>Status:</b></span>
    <div *ngFor="let data of pageParams.statusData">
      <i nz-icon [nzType]="data.icon" [style]="pageParams.legendIconStyle"></i>
      <span class="mr-5">{{ data.value }}</span>
    </div>
  </div>

  <div class="mt-20">
    <rd-table
      [tableData]="conceptLogGrid.tableData"
      [tableColumns]="conceptLogGrid.tableColumns"
      [isLoading]="conceptLogGrid.status === 'loading'"
      [errorMessage]="conceptLogGrid.errorMessage"
      [pageIndex]="conceptLogGrid.pageIndex"
      [pageSize]="10"
      [loadingRows]="8"
      [tableScrollDetails]="conceptLogGrid.tableScrollDetails"
      [totalCount]="conceptLogGrid.totalConceptsCount"
      [frontEndPagination]="false"
      [showPagination]="true"
      [showTotal]="tableTotal"
      (paginationChange)="onPaginationChange($event)"
      (tableRowClick)="onTableRowClick($event)"
      (sortApply)="onTableSort($event)"
      (iconClick)="handleIconClick($event)"
    >
      <ng-template #tableTotal let-range="range" let-total>
        {{ range[0] }} - {{ range[1] }} of {{ total }} Concepts
      </ng-template></rd-table
    >
  </div>
</div>

<div class="modal__container">
  <rd-modal
    [isVisible]="modalConfig.isVisible"
    [isCloseable]="true"
    [modalWidth]="modalConfig.modalWidth"
    [isMaskClosable]="false"
    [isCentered]="true"
    modalDialogClassName="modal-box"
    (modalCloseEvent)="modalConfig.isVisible = false"
  >
    <ng-container rdModalTitle>
      <div class="modal-title">
        <b>{{ modalConfig.modalTitle }}</b>
      </div>
    </ng-container>
    <ng-container rdModalContent>
      <ng-container *ngIf="modalConfig.modalName === 'conceptName'">
        <div class="concept__container">
          <ng-container *ngIf="isConceptLoaded">
            <div class="d-flex align-center mb-15" style="column-gap: 10px">
              <label class="fw-bold">Description:</label>
              <span class="fs-16 fw-normal">
                {{ codeDetails.conceptSetDesc || '-' }}
              </span>
            </div>
            <div class="mb-15">
              <label class="fw-bold fs-16">Total # of Codes: </label>
              <span class="fw-bold fs-16">{{
                codeDetails.totalNumOfCodes || '-'
              }}</span>
            </div>
            <div class="collapse-section">
              <rd-collapse
                [showArrowIcon]="true"
                [isAccordion]="true"
                [panels]="codesAccordion"
              >
                <ng-template let-header>
                  <rd-table
                    [tableData]="header.tableData"
                    [tableColumns]="header.tableColumn"
                    [tableScrollDetails]="{ y: '172px' }"
                    [bordered]="true"
                    [frontEndPagination]="false"
                    [showPagination]="false"
                  ></rd-table>
                </ng-template>
              </rd-collapse>
            </div>
          </ng-container>
          <ng-container *ngIf="!isConceptLoaded && conceptLoadErrorMessage">
            <div class="error-box">
              <rd-alert
                type="error"
                [closable]="false"
                [showIcon]="true"
                [message]="conceptLoadErrorMessage"
              ></rd-alert>
            </div>
          </ng-container>
          <ng-container *ngIf="!isConceptLoaded && !conceptLoadErrorMessage">
            <div class="spinner"><rd-spinner></rd-spinner></div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </rd-modal>
</div>

<app-onboard-dataset
  *ngIf="pageParams.showTaggingPopup"
  [isVisibleMiddle]="pageParams.showTaggingPopup"
  (closed)="onClose($event)"
></app-onboard-dataset>

<app-error-log-drawer
  *ngIf="errorLogDrawerParams.show"
  [showDrawer]="errorLogDrawerParams.show"
  [drawerData]="errorLogDrawerParams.data"
  (closeDrawer)="handleErrorLogDrawerClose()"
></app-error-log-drawer>
