import { IconDefinition } from '@ant-design/icons-angular';

export const CUSTOM_ICONS: Array<IconDefinition> = [
  {
    name: 'admin-user',
    icon:
      '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M13.5355 10.4645C12.5979 9.52678 11.3261 9 10 9C8.67392 9 7.40215 9.52678 6.46447 10.4645C5.52678 11.4021 5 12.6739 5 14V20H11.5278C10.5777 18.9385 10 17.5367 10 16C10 13.5478 11.4711 11.4391 13.579 10.5085C13.5646 10.4937 13.5501 10.4791 13.5355 10.4645Z" fill="white"/>' +
      '<path d="M3.008 13.656C3.03485 13.0968 3.12886 12.5428 3.288 12.006L3.119 12.02C2.2617 12.1139 1.46924 12.521 0.893635 13.1632C0.318029 13.8054 -0.000196625 14.6376 9.11477e-08 15.5V20H3V14L3.008 13.656Z" fill="white"/>' +
      '<path d="M5.26777 6.73223C4.79893 6.26339 4.16304 6 3.5 6C2.83696 6 2.20107 6.26339 1.73223 6.73223C1.26339 7.20107 1 7.83696 1 8.5C1 9.16304 1.26339 9.79893 1.73223 10.2678C2.20107 10.7366 2.83696 11 3.5 11C4.16304 11 4.79893 10.7366 5.26777 10.2678C5.73661 9.79893 6 9.16304 6 8.5C6 7.83696 5.73661 7.20107 5.26777 6.73223Z" fill="white"/>' +
      '<path d="M12.8284 1.17157C12.0783 0.421427 11.0609 0 10 0C8.93913 0 7.92172 0.421427 7.17157 1.17157C6.42143 1.92172 6 2.93913 6 4C6 5.06087 6.42143 6.07828 7.17157 6.82843C7.92172 7.57857 8.93913 8 10 8C11.0609 8 12.0783 7.57857 12.8284 6.82843C13.5786 6.07828 14 5.06087 14 4C14 2.93913 13.5786 1.92172 12.8284 1.17157Z" fill="white"/>' +
      '<path d="M12.595 16.812C12.4682 16.2784 12.4682 15.7226 12.595 15.189L11.603 14.616L12.603 12.884L13.595 13.457C13.9932 13.0799 14.4745 12.8017 15 12.645V11.5H17V12.645C17.532 12.803 18.012 13.085 18.405 13.457L19.397 12.884L20.397 14.616L19.405 15.189C19.5316 15.7223 19.5316 16.2777 19.405 16.811L20.397 17.384L19.397 19.116L18.405 18.543C18.0068 18.9201 17.5255 19.1983 17 19.355V20.5H15V19.355C14.4745 19.1983 13.9932 18.9201 13.595 18.543L12.603 19.116L11.603 17.384L12.595 16.812ZM16 17.5C16.3978 17.5 16.7794 17.342 17.0607 17.0607C17.342 16.7794 17.5 16.3978 17.5 16C17.5 15.6022 17.342 15.2206 17.0607 14.9393C16.7794 14.658 16.3978 14.5 16 14.5C15.6022 14.5 15.2206 14.658 14.9393 14.9393C14.658 15.2206 14.5 15.6022 14.5 16C14.5 16.3978 14.658 16.7794 14.9393 17.0607C15.2206 17.342 15.6022 17.5 16 17.5Z" fill="white"/>' +
      '</svg>',
  },
  {
    name: 'list-view',
    icon:
      '<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M5 0.5H19V2.5H5V0.5Z" fill="#2D8B93"/>' +
      '<path d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z" fill="#2D8B93"/>' +
      '<path d="M5 7H19V9H5V7Z" fill="#2D8B93"/>' +
      '<path d="M3 8C3 8.82843 2.32843 9.5 1.5 9.5C0.671573 9.5 0 8.82843 0 8C0 7.17157 0.671573 6.5 1.5 6.5C2.32843 6.5 3 7.17157 3 8Z" fill="#2D8B93"/>' +
      '<path d="M5 13.5H19V15.5H5V13.5Z" fill="#2D8B93"/>' +
      '<path d="M3 14.5C3 15.3284 2.32843 16 1.5 16C0.671573 16 0 15.3284 0 14.5C0 13.6716 0.671573 13 1.5 13C2.32843 13 3 13.6716 3 14.5Z" fill="#2D8B93"/>' +
      '</svg>',
  },
  {
    name: 'tile-view',
    icon:
      '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"> ' +
      '<path d="M20 9.999V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H11V9.999H20ZM9 9.999V18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9.999H9ZM9 0V7.999H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H9ZM19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V7.999H11V0H19Z" fill="#454250"/>' +
      '</svg>',
  },
  {
    name: 'edit',
    icon:
      '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M9.9 3.858L14.142 8.101L4.242 18H0V13.757L9.9 3.857V3.858ZM11.314 2.444L13.435 0.322C13.6225 0.134528 13.8768 0.029213 14.142 0.029213C14.4072 0.029213 14.6615 0.134528 14.849 0.322L17.678 3.151C17.8655 3.33853 17.9708 3.59284 17.9708 3.858C17.9708 4.12316 17.8655 4.37747 17.678 4.565L15.556 6.686L11.314 2.444Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'editDisabled',
    icon:
      '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M9.9 3.858L14.142 8.101L4.242 18H0V13.757L9.9 3.857V3.858ZM11.314 2.444L13.435 0.322C13.6225 0.134528 13.8768 0.029213 14.142 0.029213C14.4072 0.029213 14.6615 0.134528 14.849 0.322L17.678 3.151C17.8655 3.33853 17.9708 3.59284 17.9708 3.858C17.9708 4.12316 17.8655 4.37747 17.678 4.565L15.556 6.686L11.314 2.444Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'clone',
    icon:
      '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.009 4H4ZM2.002 6L2 18H12V6H2.002ZM6 4H14V14H16V2H6V4ZM4 9H10V11H4V9ZM4 13H10V15H4V13Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'cloneDisabled',
    icon:
      '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.009 4H4ZM2.002 6L2 18H12V6H2.002ZM6 4H14V14H16V2H6V4ZM4 9H10V11H4V9ZM4 13H10V15H4V13Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'download',
    icon:
      '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'downloadDisabled',
    icon:
      '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'share',
    icon:
      '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M11.5762 15.271L6.4662 12.484C5.97569 12.9709 5.35201 13.3017 4.67379 13.4346C3.99557 13.5676 3.29316 13.4968 2.65513 13.2311C2.01709 12.9654 1.47199 12.5168 1.08855 11.9418C0.705108 11.3668 0.500488 10.6911 0.500488 9.99999C0.500488 9.30886 0.705108 8.63319 1.08855 8.05818C1.47199 7.48317 2.01709 7.03455 2.65513 6.76889C3.29316 6.50322 3.99557 6.43241 4.67379 6.56536C5.35201 6.69832 5.97569 7.02909 6.4662 7.51599L11.5762 4.72899C11.4009 3.90672 11.5275 3.04885 11.9328 2.31224C12.3381 1.57563 12.9949 1.00952 13.7833 0.717422C14.5717 0.425324 15.4388 0.426763 16.2262 0.721475C17.0136 1.01619 17.6686 1.58448 18.0714 2.32243C18.4742 3.06037 18.598 3.91866 18.42 4.74034C18.242 5.56202 17.7742 6.29218 17.1021 6.79729C16.43 7.3024 15.5986 7.54871 14.7598 7.49119C13.9211 7.43366 13.131 7.07613 12.5342 6.48399L7.4242 9.27099C7.52613 9.75164 7.52613 10.2483 7.4242 10.729L12.5342 13.516C13.131 12.9238 13.9211 12.5663 14.7598 12.5088C15.5986 12.4513 16.43 12.6976 17.1021 13.2027C17.7742 13.7078 18.242 14.438 18.42 15.2596C18.598 16.0813 18.4742 16.9396 18.0714 17.6776C17.6686 18.4155 17.0136 18.9838 16.2262 19.2785C15.4388 19.5732 14.5717 19.5747 13.7833 19.2826C12.9949 18.9905 12.3381 18.4243 11.9328 17.6877C11.5275 16.9511 11.4009 16.0933 11.5762 15.271Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'shareDisabled',
    icon:
      '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M11.5762 15.271L6.4662 12.484C5.97569 12.9709 5.35201 13.3017 4.67379 13.4346C3.99557 13.5676 3.29316 13.4968 2.65513 13.2311C2.01709 12.9654 1.47199 12.5168 1.08855 11.9418C0.705108 11.3668 0.500488 10.6911 0.500488 9.99999C0.500488 9.30886 0.705108 8.63319 1.08855 8.05818C1.47199 7.48317 2.01709 7.03455 2.65513 6.76889C3.29316 6.50322 3.99557 6.43241 4.67379 6.56536C5.35201 6.69832 5.97569 7.02909 6.4662 7.51599L11.5762 4.72899C11.4009 3.90672 11.5275 3.04885 11.9328 2.31224C12.3381 1.57563 12.9949 1.00952 13.7833 0.717422C14.5717 0.425324 15.4388 0.426763 16.2262 0.721475C17.0136 1.01619 17.6686 1.58448 18.0714 2.32243C18.4742 3.06037 18.598 3.91866 18.42 4.74034C18.242 5.56202 17.7742 6.29218 17.1021 6.79729C16.43 7.3024 15.5986 7.54871 14.7598 7.49119C13.9211 7.43366 13.131 7.07613 12.5342 6.48399L7.4242 9.27099C7.52613 9.75164 7.52613 10.2483 7.4242 10.729L12.5342 13.516C13.131 12.9238 13.9211 12.5663 14.7598 12.5088C15.5986 12.4513 16.43 12.6976 17.1021 13.2027C17.7742 13.7078 18.242 14.438 18.42 15.2596C18.598 16.0813 18.4742 16.9396 18.0714 17.6776C17.6686 18.4155 17.0136 18.9838 16.2262 19.2785C15.4388 19.5732 14.5717 19.5747 13.7833 19.2826C12.9949 18.9905 12.3381 18.4243 11.9328 17.6877C11.5275 16.9511 11.4009 16.0933 11.5762 15.271Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'delete',
    icon:
      '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M15 2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5V0H15V2ZM7 7V15H9V7H7ZM11 7V15H13V7H11Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'deleteDisabled',
    icon:
      '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M15 2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5V0H15V2ZM7 7V15H9V7H7ZM11 7V15H13V7H11Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'more-dots',
    icon: `
      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.97644C-4.1511e-09 2.17342 0.0387985 2.36848 0.114181 2.55047C0.189563 2.73245 0.300052 2.89781 0.43934 3.0371C0.578628 3.17639 0.743987 3.28688 0.925975 3.36226C1.10796 3.43764 1.30302 3.47644 1.5 3.47644C1.69698 3.47644 1.89204 3.43764 2.07403 3.36226C2.25601 3.28688 2.42137 3.17639 2.56066 3.0371C2.69995 2.89781 2.81044 2.73245 2.88582 2.55047C2.9612 2.36848 3 2.17342 3 1.97644C3 1.77946 2.9612 1.5844 2.88582 1.40242C2.81044 1.22043 2.69995 1.05507 2.56066 0.91578C2.42137 0.776492 2.25601 0.666003 2.07403 0.590621C1.89204 0.515239 1.69698 0.47644 1.5 0.47644C1.30302 0.47644 1.10796 0.515239 0.925975 0.590621C0.743987 0.666003 0.578628 0.776492 0.43934 0.91578C0.300052 1.05507 0.189563 1.22043 0.114181 1.40242C0.0387985 1.5844 -4.1511e-09 1.77946 0 1.97644ZM7.5 1.97644C7.5 2.37427 7.65804 2.7558 7.93934 3.0371C8.22064 3.3184 8.60218 3.47644 9 3.47644C9.39783 3.47644 9.77936 3.3184 10.0607 3.0371C10.342 2.7558 10.5 2.37427 10.5 1.97644C10.5 1.57862 10.342 1.19708 10.0607 0.91578C9.77936 0.634475 9.39783 0.47644 9 0.47644C8.60218 0.47644 8.22064 0.634475 7.93934 0.91578C7.65804 1.19708 7.5 1.57862 7.5 1.97644ZM15 1.97644C15 2.37427 15.158 2.7558 15.4393 3.0371C15.7206 3.3184 16.1022 3.47644 16.5 3.47644C16.8978 3.47644 17.2794 3.3184 17.5607 3.0371C17.842 2.7558 18 2.37427 18 1.97644C18 1.57862 17.842 1.19708 17.5607 0.91578C17.2794 0.634475 16.8978 0.47644 16.5 0.47644C16.1022 0.47644 15.7206 0.634475 15.4393 0.91578C15.158 1.19708 15 1.57862 15 1.97644Z" fill="#27A6A4"/>
      </svg>
    `,
  },
  {
    name: 'dialog-warning',
    icon: `
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25664_112571)">
      <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" fill="#E3A900"/>
      <path d="M9.82129 15.3214C9.82129 15.634 9.94546 15.9338 10.1665 16.1548C10.3875 16.3758 10.6873 16.5 10.9999 16.5C11.3124 16.5 11.6122 16.3758 11.8332 16.1548C12.0543 15.9338 12.1784 15.634 12.1784 15.3214C12.1784 15.0089 12.0543 14.7091 11.8332 14.4881C11.6122 14.267 11.3124 14.1429 10.9999 14.1429C10.6873 14.1429 10.3875 14.267 10.1665 14.4881C9.94546 14.7091 9.82129 15.0089 9.82129 15.3214ZM10.4106 12.5714H11.5891C11.6972 12.5714 11.7856 12.483 11.7856 12.375V5.69643C11.7856 5.58839 11.6972 5.5 11.5891 5.5H10.4106C10.3025 5.5 10.2141 5.58839 10.2141 5.69643V12.375C10.2141 12.483 10.3025 12.5714 10.4106 12.5714Z" fill="#E3A900"/>
      </g>
      <defs>
      <clipPath id="clip0_25664_112571">
      <rect width="22" height="22" fill="white"/>
      </clipPath>
      </defs>
      </svg>
    `,
  },
  {
    name: 'dialog-success',
    icon: `
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_28876_14092)">
      <path d="M15.5907 7.09521H14.4392C14.1887 7.09521 13.9505 7.21553 13.8032 7.42178L9.94339 12.7745L8.19518 10.3486C8.04786 10.1448 7.81214 10.022 7.55924 10.022H6.40768C6.24808 10.022 6.15478 10.2037 6.24808 10.3338L9.30745 14.5767C9.37973 14.6776 9.475 14.7598 9.58538 14.8165C9.69577 14.8732 9.81807 14.9027 9.94216 14.9027C10.0663 14.9027 10.1886 14.8732 10.2989 14.8165C10.4093 14.7598 10.5046 14.6776 10.5769 14.5767L15.7479 7.40705C15.8436 7.27691 15.7503 7.09521 15.5907 7.09521Z" fill="#00AA67"/>
      <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" fill="#00AA67"/>
      </g>
      <defs>
      <clipPath id="clip0_28876_14092">
      <rect width="22" height="22" fill="white"/>
      </clipPath>
      </defs>
      </svg>
    `,
  },
  {
    name: 'step1',
    icon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 21.5C5.20101 21.5 0.5 16.799 0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11C21.5 16.799 16.799 21.5 11 21.5Z" stroke="#2F2C3C"/>
    <path d="M10.915 16H9.80762V8.91797C9.80762 8.64909 9.80762 8.41895 9.80762 8.22754C9.81217 8.03158 9.81673 7.85384 9.82129 7.69434C9.8304 7.53027 9.8418 7.36393 9.85547 7.19531C9.71419 7.34115 9.58203 7.46647 9.45898 7.57129C9.33594 7.67155 9.18327 7.79688 9.00098 7.94727L7.85938 8.86328L7.26465 8.09082L9.97168 6.00586H10.915V16Z" fill="#2F2C3C"/>
    </svg>
    `,
  },
  {
    name: 'step2',
    icon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11Z" stroke="#2F2C3C"/>
    <path d="M13.2393 16H6.69043V15.0498L9.36328 12.3359C9.86003 11.8346 10.277 11.388 10.6143 10.9961C10.9561 10.6042 11.2158 10.2191 11.3936 9.84082C11.5713 9.45801 11.6602 9.03874 11.6602 8.58301C11.6602 8.0179 11.4915 7.58724 11.1543 7.29102C10.8216 6.99023 10.3818 6.83984 9.83496 6.83984C9.35645 6.83984 8.93262 6.92188 8.56348 7.08594C8.19434 7.25 7.81608 7.48242 7.42871 7.7832L6.82031 7.01758C7.08464 6.79427 7.37174 6.59603 7.68164 6.42285C7.99609 6.24967 8.33105 6.11296 8.68652 6.0127C9.04655 5.91243 9.42936 5.8623 9.83496 5.8623C10.4502 5.8623 10.9811 5.9694 11.4277 6.18359C11.8743 6.39779 12.2184 6.70312 12.46 7.09961C12.7061 7.49609 12.8291 7.96777 12.8291 8.51465C12.8291 9.04329 12.7243 9.53548 12.5146 9.99121C12.305 10.4424 12.0111 10.8913 11.6328 11.3379C11.2546 11.7799 10.8125 12.2493 10.3066 12.7461L8.13281 14.9131V14.9609H13.2393V16Z" fill="#2F2C3C"/>
    </svg>
    `,
  },
  {
    name: 'step3',
    icon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11Z" stroke="#2F2C3C"/>
    <path d="M12.8701 8.33691C12.8701 8.78353 12.7835 9.1709 12.6104 9.49902C12.4372 9.82715 12.1956 10.096 11.8857 10.3057C11.5758 10.5107 11.2113 10.6543 10.792 10.7363V10.791C11.585 10.8913 12.1797 11.1465 12.5762 11.5566C12.9772 11.9668 13.1777 12.5046 13.1777 13.1699C13.1777 13.7487 13.041 14.2614 12.7676 14.708C12.4987 15.1546 12.084 15.5055 11.5234 15.7607C10.9674 16.0114 10.2542 16.1367 9.38379 16.1367C8.85514 16.1367 8.36523 16.0934 7.91406 16.0068C7.46745 15.9248 7.03906 15.7858 6.62891 15.5898V14.5234C7.04362 14.7285 7.49479 14.8903 7.98242 15.0088C8.47005 15.1227 8.94173 15.1797 9.39746 15.1797C10.3089 15.1797 10.9674 14.9997 11.373 14.6396C11.7786 14.2751 11.9814 13.7783 11.9814 13.1494C11.9814 12.7165 11.8675 12.3678 11.6396 12.1035C11.4163 11.8346 11.0928 11.6387 10.6689 11.5156C10.2497 11.388 9.74382 11.3242 9.15137 11.3242H8.15332V10.3535H9.1582C9.70052 10.3535 10.1608 10.2738 10.5391 10.1143C10.9173 9.95475 11.2044 9.73145 11.4004 9.44434C11.6009 9.15267 11.7012 8.80859 11.7012 8.41211C11.7012 7.90625 11.5326 7.5166 11.1953 7.24316C10.8581 6.96517 10.4001 6.82617 9.82129 6.82617C9.46582 6.82617 9.14225 6.86263 8.85059 6.93555C8.55892 7.00846 8.28548 7.10872 8.03027 7.23633C7.77507 7.36393 7.51986 7.51204 7.26465 7.68066L6.69043 6.90137C7.05501 6.61882 7.49935 6.375 8.02344 6.16992C8.54753 5.96484 9.14225 5.8623 9.80762 5.8623C10.8285 5.8623 11.5941 6.09473 12.1045 6.55957C12.6149 7.02441 12.8701 7.61686 12.8701 8.33691Z" fill="#2F2C3C"/>
    </svg>
    `,
  },
  {
    name: 'line',
    icon: `<svg width="1" height="28" viewBox="0 0 1 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="28" stroke="#9C9AA1"/>
    </svg>
    `,
  },
  {
    name: 'success',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9.003 14L4.76 9.757L6.174 8.343L9.003 11.172L14.659 5.515L16.074 6.929L9.003 14Z" fill="#27A6A4"/>
    </svg>
    `,
  },
  {
    name: 'failed',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z" fill="#B21111"/>
    </svg>
    `,
  },
  {
    name: 'waiting',
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z" fill="#B2B0B6"/>
    </svg>
    `,
  },
  {
    name: 'dash',
    icon: `<svg width="7" height="2" viewBox="0 0 7 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H7" stroke="#27a6a4"/>
    </svg>
    `,
  },
  {
    name: 'upload',
    icon: `<svg width="7" height="2" viewBox="0 0 7 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H7" stroke="#27a6a4"/>
    </svg>
    `,
  },
];
