import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ApiEndpoints } from '@core/constants';
import { saveAs } from 'file-saver';
import { TransactionManagerService } from '@core/services';

@Component({
  selector: 'app-error-log-drawer',
  templateUrl: './error-log-drawer.component.html',
  styleUrls: ['./error-log-drawer.component.scss'],
})
export class ErrorLogDrawerComponent implements AfterViewInit {
  @Input() showDrawer = false;

  @Input() drawerData: any; // TODO: Add type.

  @Output() closeDrawer = new EventEmitter<void>();

  errorLogFetchStatus: '' | 'loading' | 'available' | 'unavailable' | 'error' =
    'loading';

  showDownloadLoader = false;

  errorLogDetails: ErrorLogDetails[] = [];

  errorLogsText = '';

  errorLogFailedMessage = '';

  private errorLogsBlob?: Blob;

  constructor(private dataService: TransactionManagerService) {}

  ngAfterViewInit(): void {
    this.setDatasetDetails();
    this.fetchErrorLogs();
  }

  private setDatasetDetails() {
    this.errorLogDetails = [
      {
        title: 'Job ID',
        description: this.drawerData.jobId,
      },
      {
        title: 'Dataset Name',
        description: this.drawerData.datasetName,
      },
      {
        title: 'Cluster ID',
        description: this.drawerData.clusterId,
      },
      {
        title: 'Onboarded By',
        description: this.drawerData.onboardedBy,
      },
    ];
  }

  private fetchErrorLogs(): void {
    this.errorLogFetchStatus = 'loading';
    const payload = {
      clusterId: this.drawerData.clusterId,
      jobId: this.drawerData.jobId,
    };

    // TODO: Add response type.
    this.dataService
      .get(
        ApiEndpoints.ADMIN_ERROR_LOG +
          '?clusterId=' +
          payload.clusterId +
          '&jobId=' +
          payload.jobId
      )
      .subscribe({
        next: (response) => {
          if (response?.status === 'SUCCESS') {
            try {
              const blob = this.base64ToBlob(response?.['data'], 'text/plain');
              this.errorLogsBlob = blob;
              blob.text().then((text) => {
                this.errorLogsText = text;
                this.errorLogFetchStatus = 'available';
              });
            } catch {
              this.errorLogsText = response?.data;
              this.errorLogFetchStatus = 'available';
            }
          } else {
            this.errorLogFailedMessage = response?.message;
            this.errorLogFetchStatus = 'error';
          }
        },
        error: () => {
          this.errorLogFailedMessage =
            'Something went wrong. Please try agaon later.';
          this.errorLogFetchStatus = 'error';
        },
      });
  }

  private base64ToBlob(b64Data: any, contentType = '', sliceSize = 512): Blob {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadErrorLogs(): void {
    if (this.errorLogsBlob) {
      saveAs(
        this.errorLogsBlob,
        `log_${this.drawerData.clusterId}_${this.drawerData.stepId}.txt`
      );
    }
  }
}

interface ErrorLogDetails {
  title: string;
  description: string;
}
