export const environment = {
  env: 'dev',
  production: false,
  config: {
    serverUrl: 'https://concept-builder.zaidyn.dev.zsservices.com',
    loginUrl:
      'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/login.aspx?ReturnUrl=',
    logoutUrl:
      'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/logout.aspx',
    idmAccountUrl:
      'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/MyAccount.aspx',
    releaseVersion: 'R13',
    dataExplorerRedirectUrl: 'https://data-explorer.zaidyn.dev.zsservices.com',
    client: 'dev'
  },
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: 'https://concept-builder.dev.zsservices.com',
    clientId: 'mfa.cdldev',
    responseType: 'code',
    cookieDomain: 'zsservices.com',
  },
};
