import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InMemoryService } from '@core/services/in-memory.service';
import {
  RDAlertModule,
  RDButtonModule,
  RDCollapseModule,
  RDInputModule,
  RDModalModule,
  RDSelectModule,
  RDSpinnerModule,
  RDTableModule,
  RDTabSetModule,
  RDTooltipModule,
  RDDrawerModule,
} from '@zs-ca/angular-cd-library';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { AdminComponent } from './admin.component';
import { ApproveRequestsComponent } from './approve-requests/approve-requests.component';
import { ConceptOnboardingComponent } from './concept-onboarding/concept-onboarding.component';
import { OnboardDatasetComponent } from './onboard-dataset/onboard-dataset.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { ErrorLogDrawerComponent } from './error-log-drawer/error-log-drawer.component';

@NgModule({
  declarations: [
    AdminComponent,
    ApproveRequestsComponent,
    ConceptOnboardingComponent,
    OnboardDatasetComponent,
    AuditTrailComponent,
    ErrorLogDrawerComponent,
  ],
  imports: [
    CommonModule,
    RDTabSetModule,
    RDAlertModule,
    RDInputModule,
    RDSpinnerModule,
    RDButtonModule,
    RDModalModule,
    RDSelectModule,
    RDCollapseModule,
    RDTableModule,
    HttpClientModule,
    NzIconModule,
    RDTooltipModule,
    RDDrawerModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemoryService, {
      dataEncapsulation: true,
    }),
  ],
  providers: [InMemoryService],
})
export class AdminModule {}
