import { InMemoryDbService } from 'angular-in-memory-web-api';

export class InMemoryService implements InMemoryDbService {
  createDb(): object {
    const conceptListData = {
      status: 'success',
      totalConceptsCount: 25,
      data: [
        {
          conceptName: 'Test1',
          conceptDescription: 'Desc',
          domain: 'Condition',
          createdBy: 'Sakshi',
          createdOn: '06/28/2019 10:27:35',
          lastUpdatedBy: 'Aditya',
          lastUpdatedOn: '06/28/2019 10:27:35',
          publishStatus: 'Approved',
          globalFilterStatus: 'Failed',
          approver: 'Sakshi',
          isLocked: false,
          lockedBy: 'Sakshi',
          lockedByUserID: 'sm18591',
          numberOfCodes: 20,
          datasetsOnboarded: 30,
          conceptSetId: '1',
        },
        {
          conceptName: 'Test2',
          conceptDescription: 'Desc',
          domain: 'Condition',
          createdBy: 'Sakshi',
          createdOn: '06/23/2020 10:27:35',
          lastUpdatedBy: 'Aditya',
          lastUpdatedOn: '',
          publishStatus: 'Approved',
          globalFilterStatus: 'Failed',
          approver: 'Sakshi',
          isLocked: false,
          lockedBy: 'Sakshi',
          lockedByUserID: 'sm18591',
          numberOfCodes: 20,
          datasetsOnboarded: 30,
          conceptSetId: '2',
        },
        {
          conceptName: 'Test',
          conceptDescription: 'Desc',
          domain: 'Condition',
          createdBy: 'Sakshi',
          createdOn: '',
          lastUpdatedBy: 'Aditya',
          lastUpdatedOn: '',
          publishStatus: 'Approved',
          globalFilterStatus: 'Failed',
          approver: 'Sakshi',
          isLocked: false,
          lockedBy: 'Sakshi',
          lockedByUserID: 'sm18591',
          numberOfCodes: 20,
          datasetsOnboarded: 30,
          conceptSetId: '3',
        },
      ],
    };

    const codeDetails = {
      status: 'success',
      conceptSetName: 'Test1',
      conceptSetDesc: 'Thyroid Cancer codes for Drugs and Condition Domain.',
      totalNumOfCodes: 30,
      codes: {
        DRUG: {
          noOfCodes: 10,
          data: [
            {
              vocabulary: 'CTD1',
              domain: '396-01-1',
              code: 'test3',
              description: 'test drug',
            },
            {
              vocabulary: 'CTD2',
              domain: '396-01',
              code: 'test4',
              description: 'test drug2',
            },
            {
              vocabulary: 'CTD1',
              domain: '396-01-1',
              code: 'test3',
              description: 'test drug',
            },
            {
              vocabulary: 'CTD2',
              domain: '396-01',
              code: 'test4',
              description: 'test drug2',
            },
            {
              vocabulary: 'CTD1',
              domain: '396-01-1',
              code: 'test3',
              description: 'test drug',
            },
            {
              vocabulary: 'CTD2',
              domain: '396-01',
              code: 'test4',
              description: 'test drug2',
            },
          ],
        },
        CONDITION: {
          noOfCodes: 30,
          data: [
            {
              vocabulary: 'CTD',
              domain: '396-01-1',
              code: 'test',
              description: 'test drug',
            },
          ],
        },
        PROCEDURE: {
          noOfCodes: 10,
          data: [
            {
              vocabulary: 'CTD',
              domain: '396-01-1',
              code: 'test',
              description: 'test drug',
            },
            {
              vocabulary: 'CTD',
              domain: '396-01-1',
              code: 'test',
              description: 'test drug',
            },
          ],
        },
        MEASUREMENT: {
          noOfCodes: 30,
          data: [
            {
              vocabulary: 'CTD',
              domain: '396-01-1',
              code: 'test',
              description: 'test drug',
            },
          ],
        },
        PROVIDER: {
          noOfCodes: 30,
          data: [
            {
              vocabulary: 'CTD',
              domain: '396-01-1',
              code: 'test',
              description: 'test drug',
            },
          ],
        },
      },
    };

    const datasetDetails = {
      status: 'success',
      noOfDatasets: 29,
      dataSets: [
        {
          datasetName: 'Claims Data',
          datasetId: '123',
          patientCount: 120000000,
          s3Location: ['https://google.com', 'https://test.com'],
        },
        {
          datasetName: 'Ab Data',
          datasetId: '123',
          patientCount: 12000,
          s3Location: ['https://google.com', 'https://test.com'],
        },
      ],
    };

    const downloadCodes = {
      status: 'success',
      downloadUrl: 'https://google.com',
    };

    const conceptRequestList = {
      status: 'SUCCESS',
      totalRequestsCount: 20,
      data: [
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000730',
          conceptName: 'trial 123',
          domain: 'CONDITION,DRUG',
          submittedOn: '09/21/2022 07:13:12',
          conceptSetDescription: 'description',
          submittedBy: 'Ishika Shah',
          isImportant: '1',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000730',
          conceptName: 'trial 123',
          domain: 'CONDITION,DRUG',
          submittedOn: '09/21/2022 07:13:12',
          conceptSetDescription: 'description',
          submittedBy: 'Ishika Shah',
          isImportant: '1',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000887',
          conceptName: 'Hypertension_QATest_20220913013047',
          domain: 'CONDITION',
          submittedOn: '09/21/2022 11:46:52',
          conceptSetDescription: 'checking unlock',
          submittedBy: 'Ishika Shah',
          isImportant: '0',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
        {
          conceptSetId: '50000730',
          conceptName: 'trial 123',
          domain: 'CONDITION,DRUG',
          submittedOn: '09/21/2022 07:13:12',
          conceptSetDescription: 'description',
          submittedBy: 'Ishika Shah',
          isImportant: '1',
          publishedFlag: '1',
          isGlobalFilter: '1',
        },
      ],
    };

    const conceptLogGrid = {
      status: 'SUCCESS',
      conceptsCount: 20,
      data: [
        {
          conceptName: 'Test Concept',
          datasetName: 'ABCD',
          onboardedOn: '06/23/2020 10:27:35',
          onboardedBy: 'Aditya Sharma',
          lastUpdatedDate: '06/23/2020 10:27:35',
          datacutStatus: 'W',
          metricsStatus: 'W',
          filterStatus: 'W',
        },
        {
          conceptName: 'Test Concept',
          datasetName: 'ABCD',
          onboardedOn: '06/23/2020 10:27:35',
          onboardedBy: 'Aditya Sharma',
          lastUpdatedOn: '06/23/2020 10:27:35',
          datacutStatus: 'S',
          metricsStatus: 'S',
          filterStatus: 'W',
        },
        {
          conceptName: 'Test Concept',
          datasetName: 'ABCD',
          onboardedOn: '06/23/2020 10:27:35',
          onboardedBy: 'Aditya Sharma',
          lastUpdatedOn: '06/23/2020 10:27:35',
          datacutStatus: 'F',
          metricsStatus: 'W',
          filterStatus: 'W',
        },
      ],
    };

    const onboardingDropdownsData = {
      status: 'SUCCESS',
      message: 'string',
      isOnboarded: true,
      conceptSetDetails: [
        {
          conceptSetId: 50000785,
          conceptSetName: 'Testing create concept',
        },
        {
          conceptSetId: 50000728,
          conceptSetName: 'testing 2',
        },
        {
          conceptSetId: 50000726,
          conceptSetName: 'testing 1',
        },
      ],
      datasetDetails: [
        {
          dataSourceId: 90000030,
          dataSourceName: 'NEWDATASETPATIENTCLAIMSCOUNT',
          path: '',
          pathEnable: false,
          pathInvalid: false,
        },
        {
          dataSourceId: 90000274,
          dataSourceName: 'DATA_ONBOARD_JULY2022',
          path: '',
          pathEnable: false,
          pathInvalid: false,
        },
        {
          dataSourceId: 90000278,
          dataSourceName: 'DATA_ONBOARD_2022',
          path: '',
          pathEnable: false,
          pathInvalid: false,
        },
      ],
    };

    const triggerOnboarding = {
      message: '',
      status: 'SUCCESS',
      onboardingSuccess: true,
    };

    const codesData = {
      status: 'SUCCESS',
      data: [
        {
          domain: 'drug',
          vocabulary: 'ATC',
          code: '03023',
          description: 'text1',
          id: 1,
        },
        {
          domain: 'drug',
          vocabulary: 'ATC',
          code: '03011',
          description: 'text2',
          id: 2,
        },
        {
          domain: 'procedure',
          vocabulary: 'PRO',
          code: '4567',
          description: 'text4',
          id: 3,
        },
        {
          domain: 'procedure',
          vocabulary: 'CED',
          code: '6578',
          description: 'text5',
          id: 4,
        },
        {
          domain: 'procedure',
          vocabulary: 'URE',
          code: '91011',
          description: 'text6',
          id: 5,
        },
        {
          domain: 'procedure',
          vocabulary: 'ERU',
          code: '121314',
          description: 'text7',
          id: 6,
        },
        {
          domain: 'measurement',
          vocabulary: 'AYT',
          code: '987',
          description: 'text6',
          id: 7,
        },
      ],
    };

    const vocabData = {
      status: 'SUCCESS',
      message: '',
      vocabularyCount: 20,
      vocabularies: [
        { vocabularyName: 'ABC', domain: 'Drug' },
        { vocabularyName: 'DEF', domain: 'Drug' },
        { vocabularyName: 'GHI', domain: 'Drug' },
        { vocabularyName: 'JKL', domain: 'Drug' },
        { vocabularyName: 'ABC', domain: 'Drug' },
        { vocabularyName: 'DEF', domain: 'Drug' },
        { vocabularyName: 'GHI', domain: 'Drug' },
        { vocabularyName: 'JKL', domain: 'Drug' },
        { vocabularyName: 'ABC', domain: 'Drug' },
        { vocabularyName: 'DEF', domain: 'Drug' },
        { vocabularyName: 'GHI', domain: 'Drug' },
        { vocabularyName: 'JKL', domain: 'Drug' },
      ],
    };

    const auditData = {
      status: 'SUCCESS',
      message: '',
      conceptId: '',
      createdOn: '06/28/2019 10:27:35',
      createdById: '',
      createdByName: 'Perman',
      comment: '',
      data: [
        {
          lastModifiedOn: '06/28/2019 10:27:35',
          lastModifiedBy: 'zssm18591',
          byUsername: 'Sakshi Mehrotra',
          comments: 'Placeholder comment',
        },
        {
          lastModifiedOn: '06/29/2019 10:27:35',
          lastModifiedBy: 'zssm18591',
          byUsername: 'Sakshi Mehrotra',
          comments: 'Placeholder comment',
        },
        {
          lastModifiedOn: '06/28/2019 10:27:35',
          lastModifiedBy: 'zssm18591',
          byUsername: 'Aditya Sharma',
          comments: 'Placeholder comment',
        },
      ],
    };

    const domainData = {
      status: 'SUCCESS',
      message: '',
      domains: [
        'Drug',
        'Procedure',
        'Condition',
        'Measurement',
        'Provider',
        'All Domains',
      ],
    };

    const downloadImportTemplate = {
      status: 'SUCCESS',
    };

    const readImportCodes = {
      status: 'SUCCESS',
      invalidCodes: [
        {
          code: 'ABCD',
          description: 'Test description',
          vocabulary: 'ATC',
          domain: 'Drug',
        },
        {
          code: 'ABCD',
          description: 'Test description',
          vocabulary: 'ATC',
          domain: 'Drug',
        },
        {
          code: 'ABCD',
          description: 'Test description',
          vocabulary: 'ATC',
          domain: 'Drug',
        },
        {
          code: 'ABCD',
          description: 'Test description',
          vocabulary: 'ATC',
          domain: 'Drug',
        },
        {
          code: 'ABCD',
          description: 'Test description',
          vocabulary: 'ATC',
          domain: 'Drug',
        },
      ],
      message: 'Invalid columns in file',
      validCodes: [
        {
          domain: 'drug',
          vocabulary: 'ATC',
          code: '03023',
          description: 'text1',
          id: 1,
        },
        {
          domain: 'drug',
          vocabulary: 'ATC',
          code: '03022',
          description: 'text2',
          id: 100,
        },
        {
          domain: 'procedure',
          vocabulary: 'PROT',
          code: '45678',
          description: 'text5',
          id: 101,
        },
      ],
    };

    const saveConceptCodes = {
      status: 'SUCCESS',
      message: '',
      codesSaved: true,
    };

    const getConceptCodes = {
      status: 'SUCCESS',
      message: '',
      codes: [
        {
          domain: 'Drug',
          vocabulary: 'ATC',
          code: '030245',
          description: 'Text1',
          id: 1,
        },
        {
          domain: 'Drug',
          vocabulary: 'ATQ',
          code: '030265',
          description: 'Text2',
          id: 2,
        },
        {
          domain: 'Drug',
          vocabulary: 'ATR',
          code: '030265',
          description: 'Text3',
          id: 3,
        },
        {
          domain: 'Drug',
          vocabulary: 'ATY',
          code: '030275',
          description: 'Text4',
          id: 4,
        },
        {
          domain: 'Drug',
          vocabulary: 'ATU',
          code: '030285',
          description: 'Text5',
          id: 5,
        },
      ],
    };

    const createConcept = {
      status: 'SUCCESS',
      isNameDuplicate: false,
      conceptId: '123456',
      conceptName: 'Test Concept Creation',
    };

    return {
      concept_sets: conceptListData,
      view_concept_set: codeDetails,
      datasets_onboarded: datasetDetails,
      download_codes: downloadCodes,
      approve_requests_list: conceptRequestList,
      get_onboarding_log: conceptLogGrid,
      concept_onboarding_details: onboardingDropdownsData,
      trigger_concept_onboarding: triggerOnboarding,
      search_concept_codes: codesData,
      view_vocabularies: vocabData,
      view_audit_trail: auditData,
      domain_list: domainData,
      import_codes_template: downloadImportTemplate,
      read_import_codes: readImportCodes,
      save_concept_codes: saveConceptCodes,
      concept_codes: getConceptCodes,
      create_concept: createConcept,
    };
  }
}
