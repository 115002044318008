export class Utils {
  /**
   * Format date time
   *
   * @param dateString date as string
   * @returns date time string formatted as dd mmm yyyy hh:mm:ss
   */
  static getTime(dateString: string) {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    return date
      .toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      .toLowerCase();
  }

  static titleCase(str: any) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  static formatGridData(row: any) {
    for (let key in row) {
      if (
        typeof key === 'string' &&
        (row[key] === null || row[key] === 'undefined' || row[key] === '')
      )
        row[key] = '-';
    }
  }
}
