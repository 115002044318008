import { Component, OnInit } from '@angular/core';
import {
  API_SUCCESS,
  ApiEndpoints,
  AUDIT_NO_DATA,
  SOMETHING_WENT_WRONG,
} from '@core/constants';
import { AUDIT_TRAIL_COLUMNS } from '@core/models/admin.model';
import { TransactionManagerService } from '@core/services';
import { Utils } from '@shared/Utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss'],
})
export class AuditTrailComponent implements OnInit {
  auditGrid = {
    tableData: [],
    tableColumns: AUDIT_TRAIL_COLUMNS(),
    pageIndex: 1,
    totalRequestsCount: 0,
    createdOn: '',
    createdBy: '',
    errorMessage: '',
    isLoading: false,
    limit: 10,
    offset: 0,
  };

  isDropDownLoading = false;

  isDropDownAPIFailed = false;

  dropDownData = {
    deletedConcept: [] as any[],
    activeConcept: [] as any[],
    concept_type: ['Active', 'Deleted'],
    selectedConceptType: '-',
    selectedConcept: '-',
  };

  public listSubscription: Subscription = new Subscription();

  constructor(private dataService: TransactionManagerService) {}

  ngOnInit(): void {
    this.loadDropDown();
    this.auditGrid.isLoading = true;
  }

  loadDropDown() {
    this.isDropDownLoading = true;
    this.listSubscription?.unsubscribe();
    this.listSubscription = this.dataService
      .get(ApiEndpoints.AUDIT_TRAIL_DROPDOWN)
      .subscribe({
        next: (response) => {
          if (response?.status === API_SUCCESS) {
            this.dropDownData.activeConcept = response?.active_concepts;
            this.dropDownData.deletedConcept = response?.deleted_concepts;
            this.dropDownData.activeConcept =
              this.dropDownData.activeConcept.map((item: any) => ({
                ...item,
                label: item.con_set_nm,
                value: item.con_set_id,
              }));
            this.dropDownData.deletedConcept =
              this.dropDownData.deletedConcept.map((item: any) => ({
                ...item,
                label: item.con_set_nm,
                value: item.con_set_id,
              }));
            this.dropDownData.selectedConceptType =
              this.dropDownData.concept_type[0];
            if (this.dropDownData.selectedConceptType === 'Active') {
              this.dropDownData.selectedConcept =
                this.dropDownData.activeConcept[0].value;
            } else {
              {
                this.dropDownData.selectedConcept =
                  this.dropDownData.deletedConcept[0].value;
              }
            }
            this.loadAuditGrid();
            this.isDropDownLoading = false;
            this.isDropDownAPIFailed = false;
          } else {
            this.isDropDownLoading = false;
            this.isDropDownAPIFailed = true;
          }
        },
        error: () => {
          this.isDropDownLoading = false;
          this.isDropDownAPIFailed = true;
        },
      });
  }

  loadAuditGrid() {
    this.auditGrid.isLoading = true;
    this.listSubscription?.unsubscribe();
    this.listSubscription = this.dataService
      .get(
        ApiEndpoints.AUDIT_TRAIL_GRID +
          '?con_set_id=' +
          this.dropDownData.selectedConcept
      )
      .subscribe({
        next: (response) => {
          if (response?.status === API_SUCCESS) {
            this.auditGrid.tableData = response?.data;
            this.auditGrid.totalRequestsCount = response.data.length;
            this.auditGrid.createdOn = response.createdOn;
            this.auditGrid.createdBy = response.createdBy;
            if (this.auditGrid.tableData.length === 0) {
              this.auditGrid.errorMessage = AUDIT_NO_DATA;
              this.auditGrid.isLoading = false;
            } else {
              this.transformGridData();
              this.auditGrid.isLoading = false;
            }
          } else {
            this.auditGrid.isLoading = false;
            this.auditGrid.errorMessage = SOMETHING_WENT_WRONG;
          }
        },
        error: () => {
          this.auditGrid.isLoading = false;
          this.auditGrid.errorMessage = SOMETHING_WENT_WRONG;
        },
      });
  }

  transformGridData() {
    this.auditGrid.tableData.map((data: any) => {
      if (data.lastModifiedOn) {
        data.lastModifiedOn_iconAfter = [
          {
            type: 'info-circle',
            style: { fontSize: '15px' },
            title: '',
            tooltip: `${Utils.getTime(data.lastModifiedOn)} GMT`,
          },
        ];
        data.comments = data.comments.trim();
      }
      Utils.formatGridData(data);
    });
  }

  onDropdownChange(event: any, type: string) {
    if (type === 'conceptType') {
      this.dropDownData.selectedConceptType = event;
      if (this.dropDownData.selectedConceptType === 'Active') {
        this.dropDownData.selectedConcept =
          this.dropDownData.activeConcept[0].value;
      } else {
        this.dropDownData.selectedConcept =
          this.dropDownData.deletedConcept[0].value;
      }
    } else {
      this.dropDownData.selectedConcept = event;
    }
    this.loadAuditGrid();
  }
}
