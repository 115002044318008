<rd-drawer
  closeIcon="close"
  title="Error Logs"
  width="740px"
  wrapClassName="error-log-drawer"
  [footer]="drawerFooter"
  [isCloseable]="true"
  [isVisible]="showDrawer"
  (drawerClose)="closeDrawer.emit()"
>
  <ng-container [ngSwitch]="errorLogFetchStatus">
    <ng-container
      *ngSwitchCase="'loading'"
      [ngTemplateOutlet]="loadingSpinner"
    ></ng-container>
    <ng-container
      *ngSwitchCase="'unavailable'"
      [ngTemplateOutlet]="noDataMessage"
    ></ng-container>
    <ng-container
      *ngSwitchCase="'error'"
      [ngTemplateOutlet]="errorMessage"
    ></ng-container>
    <ng-container
      *ngSwitchCase="'available'"
      [ngTemplateOutlet]="errorLogsContent"
    ></ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</rd-drawer>

<ng-template #drawerFooter>
  <rd-button
    btnType="default"
    btnText="Close"
    (btnClick)="closeDrawer.emit()"
  ></rd-button>
</ng-template>

<ng-template #errorLogsContent>
  <div class="error-log__content">
    <section class="error-log__details">
      <div class="error-log__info" *ngFor="let item of errorLogDetails">
        <p class="error-log__info-title">{{ item.title }}</p>
        <p class="error-log__info-description">{{ item.description }}</p>
      </div>
    </section>
    <div class="error-log__logs-header">
      <p class="error-log__info-title">Logs</p>
      <rd-button
        btnType="default"
        btnText="Download Logs"
        iconType="download"
        [isIconNeeded]="true"
        [isLoading]="showDownloadLoader"
        (btnClick)="downloadErrorLogs()"
      ></rd-button>
    </div>
    <p class="error-log__text">{{ errorLogsText }}</p>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="page-status-container">
    <rd-spinner></rd-spinner>
  </div>
</ng-template>

<ng-template #noDataMessage>
  <div class="page-status-container">
    <p>No datasets available.</p>
  </div>
</ng-template>

<ng-template #errorMessage>
  <div class="error-log__content">
    <section class="error-log__details">
      <div class="error-log__info" *ngFor="let item of errorLogDetails">
        <p class="error-log__info-title">{{ item.title }}</p>
        <p class="error-log__info-description">{{ item.description }}</p>
      </div>
    </section>
    <div class="error-log__logs-header">
      <p class="error-log__info-title">Logs</p>
      <rd-button
        btnType="default"
        btnText="Download Logs"
        iconType="download"
        [isIconNeeded]="true"
        [isLoading]="showDownloadLoader"
        (btnClick)="downloadErrorLogs()"
        [isDisabled]="true"
      ></rd-button>
    </div>
    <rd-alert
      type="error"
      [closable]="false"
      [showIcon]="true"
      message="{{ errorLogFailedMessage }}"
      style="margin-top: 120px"
    ></rd-alert>
  </div>
</ng-template>
