<rd-modal
  [isVisible]="isVisibleMiddle"
  [isMaskClosable]="false"
  [isCloseable]="true"
  [modalWidth]="850"
  [isCentered]="true"
  modalDialogClassName="modal-box"
  (modalCloseEvent)="closeModal()"
>
  <ng-container ngProjectAs="[rdModalTitle]">
    <span class="modal-title">Onboard New Dataset</span>
  </ng-container>
  <ng-container ngProjectAs="[rdModalContent]">
    <div class="d-flex">
      <span>Select parameters to onboard a concept to a dataset</span>
    </div>
    <div class="d-flex mt-20">
      <div class="d-flex flex-column">
        <div class="input-label">
          <label><span class="red">*</span> Concept</label>
        </div>
        <rd-select
          class="mr-20"
          [mode]="'default'"
          [isKeyLabelOptions]="true"
          [options]="conceptDropdown.items"
          [placeholder]="'Please select a Concept'"
          [isLoading]="false"
          [isDisabled]="onboardingGrid.tableData.length === 5"
          [selectStyle]="{ width: '300px' }"
          [selectSize]="'large'"
          [showSearchBox]="true"
          [allowClear]="true"
          [selectedItems]="conceptDropdown.selectedValue"
          (selectedItemsEvent)="onDropdownChange(conceptDropdown, $event)"
        ></rd-select>
      </div>
      <div class="d-flex flex-column">
        <div class="input-label">
          <label><span class="red">*</span> Dataset</label>
        </div>
        <rd-select
          [mode]="'default'"
          [isKeyLabelOptions]="true"
          [options]="datasetDropdown.items"
          [isLoading]="false"
          [placeholder]="'Please select a Dataset'"
          [isDisabled]="onboardingGrid.tableData.length === 5"
          [selectStyle]="{ width: '300px' }"
          [selectSize]="'large'"
          [showSearchBox]="true"
          [allowClear]="true"
          [selectedItems]="datasetDropdown.selectedValue"
          (selectedItemsEvent)="onDropdownChange(datasetDropdown, $event)"
        ></rd-select>
        <rd-alert
          *ngIf="
            pageParams.isDatasetOnboarded && pageParams.customPath.length === 0
          "
          iconType="warning"
          type="warning"
          [alertStyle]="{ marginTop: '0.5rem' }"
          [closable]="false"
          [message]="
            'Dataset already onboarded. Please use the custom path to onboard the dataset with a different path'
          "
          [showIcon]="true"
        ></rd-alert>
      </div>
    </div>
    <div class="d-flex mt-20 mb-15">
      <div class="d-flex flex-column custom-path" style="margin-right: 1rem">
        <div class="input-label">
          <label><b>Custom Path to add Dataset</b></label>
          <rd-tooltip
            [tooltipText]="'Path should start with s3://'"
            tooltipPlacement="top"
            iconType="info-circle"
            iconTheme="outline"
          ></rd-tooltip>
        </div>
        <rd-input
          [inputType]="'text'"
          placeholder="Add custom path to dataset"
          [currentValue]="pageParams.customPath"
          (modelChangeEvent)="pageParams.customPath = $event; validateField()"
        ></rd-input>
        <rd-alert
          *ngIf="pageParams.isCustomPathInvalid"
          iconType="warning"
          type="warning"
          [alertStyle]="{ marginTop: '0.5rem' }"
          [closable]="false"
          [message]="'Path should start with s3://'"
          [showIcon]="true"
        ></rd-alert>
      </div>
      <rd-button
        [btnType]="'default'"
        [btnText]="'Add dataset'"
        [isDisabled]="isAddDisabled()"
        (click)="addDataset()"
        style="align-self: flex-end"
      ></rd-button>
    </div>
    <!-- <div class="d-flex mt-20 mb-15">
      <rd-button
        [btnType]="'default'"
        [btnText]="'Add dataset'"
        [isDisabled]="isAddDisabled()"
        (click)="addDataset()"
      ></rd-button>
    </div> -->
    <hr class="vertical-line" />
    <label class="mt-20"
      >Concept - Dataset combination selected to be onboarded
      <b>({{ onboardingGrid.tableData.length }} / 5 )</b>
    </label>
    <div class="mt-20" style="height: 200px">
      <rd-table
        [tableData]="onboardingGrid.tableData"
        [tableColumns]="onboardingGrid.tableColumns"
        [tableScrollDetails]="{ y: '155px' }"
        [bordered]="true"
        [frontEndPagination]="false"
        [errorMessage]="'No selections made'"
        [showPagination]="false"
        (tableRowClick)="deleteDataset($event)"
      ></rd-table>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[rdModalFooter]">
    <div class="justify-content-between align-center">
      <label><span class="red">*</span> Required Field</label>
      <div>
        <rd-button
          class="mr-20"
          [btnType]="'default'"
          [btnText]="'Cancel'"
          (click)="closeModal()"
        ></rd-button>
        <rd-button
          [btnType]="'primary'"
          [isDisabled]="onboardingGrid.tableData.length === 0"
          [btnText]="'Onboard Concepts'"
          (click)="onboardConcepts()"
        ></rd-button>
      </div>
    </div>
  </ng-container>
</rd-modal>
