import { Component } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  selectedTabIndex: number = 0;
  public tabs = [
    {
      name: 'Approve Requests',
      disable: false,
      route: 'approve-requests',
    },
    {
      name: 'Concept Onboarding',
      disable: false,
      route: 'concept-onboarding',
    },
    {
      name: 'Audit Trail',
      disable: false,
      route: 'audit-trail',
    },
  ];

  constructor() {}

  onTabChange(event: any) {
    this.selectedTabIndex =
      event[0].index === 0 ? 0 : event[0].index === 1 ? 1 : 2;
  }
}
