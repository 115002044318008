import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RedirectionService {
  private isSaveSucessful = new BehaviorSubject<boolean>(false);
  currentMessage = this.isSaveSucessful.asObservable();

  constructor() {}

  changeMessage(message: boolean) {
    this.isSaveSucessful.next(message);
  }
}
