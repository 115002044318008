<div class="admin-header">
  <div class="sub-header">Admin Services</div>
  <div class="m-20">
    <rd-tabset
      [selectedTabIndex]="selectedTabIndex"
      (tabClicked)="onTabChange([$event])"
      [tabs]="tabs"
    ></rd-tabset>

    <app-approve-requests *ngIf="selectedTabIndex === 0"></app-approve-requests>
    <app-concept-onboarding
      *ngIf="selectedTabIndex === 1"
    ></app-concept-onboarding>
    <app-audit-trail *ngIf="selectedTabIndex === 2"></app-audit-trail>
  </div>
</div>
