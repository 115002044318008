export const CONCEPT_REQUEST_COLUMNS = () => [
  {
    name: 'Name',
    key: 'conceptName',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'clickable',
    width: '15%',
    ellipsis: 50,
  },
  {
    name: 'Domain',
    key: 'domain',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Submitted On',
    key: 'submittedOn',
    dataType: 'date',
    dateFormat: 'd MMM y',
    defaultSortOrder: 'desc',
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Submitted By',
    key: 'submittedBy',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Important',
    key: 'isImportant',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Global Filter',
    key: 'isGlobalFilter',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '15%',
  },
  {
    name: 'Actions',
    key: 'review',
    linkColumn: true,
    dataType: 'string',
    align: 'left',
    type: 'clickable',
    width: '15%',
  },
];

export const CODES_TABLE_COLUMNS = () => [
  {
    name: 'Vocabulary',
    key: 'vocabulary',
    dataType: 'string',
    align: 'left',
    sortable: true,
    defaultSortOrder: 'asc',
    sortDirections: ['ascend', 'descend'],
    width: '18%',
  },
  {
    name: 'Code',
    key: 'code',
    dataType: 'string',
    align: 'left',
    sortable: true,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    width: '18%',
  },
  {
    name: 'Description',
    key: 'description',
    dataType: 'string',
    align: 'left',
    sortable: true,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    ellipsis: 100,
  },
];
export interface AdminModalConfig {
  modalName: string;
  isVisible: boolean;
  modalTitle: string;
  modalWidth: string;
  footerButton?: ModalButtonConfig[];
}

export interface ModalButtonConfig {
  btnText: string;
  btnType: any;
  btnStyle: any;
  isDisabled: () => boolean;
  handler: () => void;
}

export const CONCEPT_LOG_COLUMNS = () => [
  {
    name: 'Name',
    key: 'conceptName',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'clickable',
    width: '27%',
  },
  {
    name: 'Dataset Onboarded',
    key: 'datasetName',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'clickable',
    width: '19%',
  },
  {
    name: 'Onboarded On',
    key: 'onboardedOn',
    dataType: 'date',
    dateFormat: 'd MMM y',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '10%',
  },
  {
    name: 'Onboarded By',
    key: 'onboardedBy',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '12%',
  },
  {
    name: 'Last Updated On',
    key: 'lastUpdatedDate',
    dataType: 'date',
    dateFormat: 'd MMM y',
    defaultSortOrder: 'desc',
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '11%',
  },
  {
    name: 'Status',
    key: 'status',
    align: 'left',
    width: '10%',
  },
];

export const DATASET_COLUMNS = () => [
  {
    name: 'Concept Name',
    key: 'conceptName',
    dataType: 'string',
    defaultSortOrder: 'asc',
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '40%',
  },
  {
    name: 'Dataset Name',
    key: 'dataSourceName',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '40%',
  },
  {
    name: 'Actions',
    key: 'action',
    align: 'left',
    type: 'actions',
    width: '15%',
    actionIcons: [
      {
        type: 'custom:delete',
        key: 'delete',
        title: 'Delete',
        theme: 'fill',
      },
    ],
  },
];

export const AUDIT_TRAIL_COLUMNS = () => [
  {
    name: 'Last Modified On',
    key: 'lastModifiedOn',
    dataType: 'date',
    dateFormat: 'd MMM y',
    defaultSortOrder: 'desc',
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '17%',
  },
  {
    name: 'Last Modified By',
    key: 'lastModifiedBy',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '17%',
  },
  {
    name: 'Comment',
    key: 'comments',
    dataType: 'string',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    type: 'text',
    width: '66%',
    ellipsis: 150,
  },
];
